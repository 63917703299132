import {
  ApolloError,
  ApolloQueryResult,
  QueryHookOptions as BaseQueryHookOptions,
  QueryResult,
  Unmasked,
  useQuery as useBaseQuery,
} from '@apollo/client'
import { OperationVariables } from '@apollo/client/core/types'
import { RequireAtLeastOne } from '@common/misc'
import {
  AccountPermissionFragment,
  Permission,
  PrefixedPermissions,
} from '@dolpheen/apollo'
import { handleQueryAuthError } from '@dolpheen/auth/utils'
import useAppLoader from '@hooks/useAppLoader'
import useUser from '@hooks/useUser'
import { DocumentNode } from 'graphql'
import { useIntl } from 'react-intl'

import useNotifier from './useNotifier'

const getPermissionKey = (permission: string) =>
  `PERMISSION_${permission}` as PrefixedPermissions

function getPermissionVariables(
  permissions: AccountPermissionFragment[] | undefined,
): Record<PrefixedPermissions, boolean> {
  return Object.keys(Permission).reduce(
    (acc, code) => ({
      ...acc,
      [getPermissionKey(code)]:
        permissions?.some((i) => i.code === code) ?? false,
    }),
    {} as Record<PrefixedPermissions, boolean>,
  )
}

export interface LoadMore<TData, TVariables extends OperationVariables> {
  loadMore: (
    mergeFunc: (
      prev: Unmasked<TData>,
      next: Unmasked<TData>,
    ) => Unmasked<TData>,
    extraVariables: Partial<TVariables>,
  ) => Promise<ApolloQueryResult<TData>>
}

export type UseQueryResult<
  TData,
  TVariables extends OperationVariables,
> = QueryResult<TData, TVariables> & LoadMore<TData, TVariables>

export type QueryHookOptions<
  TData,
  TVariables extends OperationVariables,
> = Partial<
  Omit<BaseQueryHookOptions<TData, TVariables>, 'variables'> & {
    displayLoader: boolean
    handleError?: (error: ApolloError) => void | undefined
    variables?: TVariables
  }
>
export type UseQueryHook<TData, TVariables extends OperationVariables> = (
  opts?: QueryHookOptions<TData, TVariables>,
) => UseQueryResult<TData, TVariables>

export function useQuery<TData, TVariables extends OperationVariables>(
  query: DocumentNode,
  {
    displayLoader,
    skip,
    variables: _bareVariables,
    fetchPolicy,
    handleError,
    ...opts
  }: QueryHookOptions<TData, TVariables> = {},
): UseQueryResult<TData, TVariables> {
  const notify = useNotifier()
  const intl = useIntl()
  const user = useUser()

  const variables = {
    ..._bareVariables,
    ...getPermissionVariables(user.user?.accountPermissions),
  } as TVariables & Record<PrefixedPermissions, boolean>

  const queryData = useBaseQuery<TData, TVariables>(query, {
    ...opts,
    errorPolicy: 'all',
    fetchPolicy: fetchPolicy ?? 'cache-and-network',
    onError: (error) => {
      if (!!handleError) {
        handleError(error)
      } else {
        handleQueryAuthError(error, notify, user.logout!, intl)
      }
    },
    skip,
    variables,
  })

  useAppLoader(displayLoader ? queryData.loading : false)

  const loadMore = (
    mergeFunc: (
      previousResults: Unmasked<TData>,
      fetchMoreResult: Unmasked<TData>,
    ) => Unmasked<TData>,
    extraVariables: RequireAtLeastOne<TVariables>,
  ) =>
    queryData.fetchMore({
      query,
      updateQuery: (previousResults, { fetchMoreResult }): Unmasked<TData> => {
        if (!fetchMoreResult) {
          return previousResults
        }
        return mergeFunc(previousResults, fetchMoreResult)
      },
      variables: { ...variables, ...extraVariables },
    })

  return {
    ...queryData,
    loadMore,
  }
}

function makeQuery<TData, TVariables extends OperationVariables>(
  query: DocumentNode,
): UseQueryHook<TData, TVariables> {
  return (opts: QueryHookOptions<TData, TVariables>) =>
    useQuery<TData, TVariables>(query, opts)
}

export default makeQuery
