import { ApolloProvider } from '@apollo/client'
import { LocaleProvider } from '@components/Locale'
import MessageManagerProvider from '@components/messages'
import ThemeProvider from '@components/Theme'
import { apolloClient } from '@dolpheen/apollo'
import AppStateProvider from '@dolpheen/containers/AppState'
import errorTracker from '@utils/errorTracking'
import React from 'react'

import AppRoutes from './AppRoutes'
import AuthProvider from './auth/AuthProvider'
import { WindowTitleProvider } from '@components/WindowTitle/context'

errorTracker.init()

const App: React.FC = () => (
  <ApolloProvider client={apolloClient}>
    <ThemeProvider>
      <LocaleProvider>
        <MessageManagerProvider>
          <AppStateProvider>
            <AuthProvider>
              <WindowTitleProvider>
                <AppRoutes />
              </WindowTitleProvider>
            </AuthProvider>
          </AppStateProvider>
        </MessageManagerProvider>
      </LocaleProvider>
    </ThemeProvider>
  </ApolloProvider>
)

export default App
