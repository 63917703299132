import { createSafeContext } from '@utils/context'
import React, { createRef, useEffect } from 'react'
import { PropsWithChildren, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { commonMessages } from '@common/intl'

interface WindowTitleConsumerProps {
  setTitle: (title: string) => string
  removeTitle: (key: string) => void
}
const [useWindowTitleContext, Provider] =
  createSafeContext<WindowTitleConsumerProps>()

export { useWindowTitleContext }

type KeyedTitle = {
  key: string
  title: string
}
export function WindowTitleProvider({ children }: PropsWithChildren) {
  const intl = useIntl()
  const titleRef = createRef<HTMLTitleElement>()

  // Remove original DOM title
  useEffect(() => {
    Array.from(document.getElementsByTagName('title')).forEach((el) => {
      if (el === titleRef.current) {
        return
      }

      el.remove()
    })
  }, [])

  const [titleStack, setTitleStack] = useState<KeyedTitle[]>([])

  const setTitle = useCallback((title: string) => {
    const key = Math.random().toString(36).substring(2, 16)
    setTitleStack((prevState) => [
      ...prevState,
      {
        key,
        title,
      },
    ])
    return key
  }, [])

  const removeTitle = useCallback((key: string) => {
    setTitleStack((prevState) => prevState.filter((i) => i.key !== key))
  }, [])
  const title = titleStack.length > 0 ? titleStack[titleStack.length - 1] : null

  const titleText = [title?.title, intl.formatMessage(commonMessages.webTitle)]
    .filter(Boolean)
    .join(' | ')

  return (
    <Provider value={{ setTitle, removeTitle }}>
      <title ref={titleRef}>{titleText}</title>
      {children}
    </Provider>
  )
}
