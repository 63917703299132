import React, { useEffect } from 'react'
import { useWindowTitleContext } from '@components/WindowTitle/context'

interface WindowTitleProps {
  title: string
}

export const WindowTitle: React.FC<WindowTitleProps> = ({ title }) => {
  const { setTitle, removeTitle } = useWindowTitleContext()
  useEffect(() => {
    const key = setTitle(title)
    return () => {
      removeTitle(key)
    }
  }, [setTitle, removeTitle, title])

  return null
}
