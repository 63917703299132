import React, { PropsWithChildren } from 'react'

type AnchorFunction = {
  (el: HTMLDivElement): void
  current: HTMLDivElement | null
}

interface AppAction {
  anchor: React.RefObject<HTMLDivElement | null> | undefined
}
export const AppActionContext = React.createContext<AppAction>({
  anchor: undefined,
})
AppActionContext.displayName = 'AppActionContext'

export const useAppActionAnchor = () => {
  const ctx = React.useContext(AppActionContext)
  if (ctx === undefined) {
    throw new Error('useBacklink must be used within a AppActionContext')
  }

  return ctx.anchor
}

export const AppActionProvider: React.FC<
  PropsWithChildren<NonNullable<unknown>>
> = ({ children }) => {
  const [anchorState, setAnchor] = React.useState<HTMLDivElement | null>(null)

  const anchor = React.useMemo(() => {
    const fn = setAnchor as AnchorFunction
    fn.current = anchorState
    return fn
  }, [anchorState])

  return (
    <AppActionContext.Provider value={{ anchor }}>
      {children}
    </AppActionContext.Provider>
  )
}
