/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as ApolloReactHooks from '@hooks/graphql'
const defaultOptions = {} as const
export const AppFragmentDoc = gql`
  fragment App on App {
    id
    name
    isActive
    tokens {
      id
      name
      token
    }
    accountPermissions {
      code
      name
    }
  }
`
export const AuthorFragmentDoc = gql`
  fragment Author on Author {
    __typename
    displayName
  }
`
export const AccountPermissionFragmentDoc = gql`
  fragment AccountPermission on AccountPermission {
    code
    name
  }
`
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    email
    firstName
    lastName
    accountPermissions {
      ...AccountPermission
    }
    roles
    avatar {
      url
    }
  }
  ${AccountPermissionFragmentDoc}
`
export const BusinessEntityListFragmentDoc = gql`
  fragment BusinessEntityList on BusinessEntity {
    id
    name
    directorUser {
      id
      displayName
    }
    teams {
      id
      name
    }
  }
`
export const BusinessEntityDetailFragmentDoc = gql`
  fragment BusinessEntityDetail on BusinessEntity {
    ...BusinessEntityList
    directorUser {
      id
      displayName
      email
    }
    aliasName
    companyName
    companyFullAddress
    companyShortAddress
    invoiceIdentifier
    bankName
    bankAccount
    websiteUrl
  }
  ${BusinessEntityListFragmentDoc}
`
export const CaseActionFragmentDoc = gql`
  fragment CaseAction on CaseAction {
    id
    type {
      id
      title
    }
    result {
      id
      title
    }
    comment
    date
    createdAt
    createdByUser {
      id
      displayName
    }
    createdByApp {
      id
      name
    }
  }
`
export const RemoteFileInfoFragmentDoc = gql`
  fragment RemoteFileInfo on RemoteFile {
    url
    mimeType
    fileName
  }
`
export const CaseDetailDocumentFragmentDoc = gql`
  fragment CaseDetailDocument on Document {
    file {
      ...RemoteFileInfo
    }
    note
    createdAt
    createdByApp {
      id
      name
    }
    createdByUser {
      id
      displayName
    }
  }
  ${RemoteFileInfoFragmentDoc}
`
export const CaseDetailServiceDocumentFragmentDoc = gql`
  fragment CaseDetailServiceDocument on ServiceDocument {
    id
    type
    otherType
    ...CaseDetailDocument
    service {
      id
      title
    }
  }
  ${CaseDetailDocumentFragmentDoc}
`
export const CaseServiceFragmentDoc = gql`
  fragment CaseService on Service {
    id
    type {
      id
      title
    }
    title
    createdAt
    dpd
    outstandingDebt {
      amount
      currency
    }
    principalAmount {
      amount
      currency
    }
    status
    serviceProvider {
      id
      name
    }
    serviceProviderContact {
      id
      displayName
      firstName
      middleName
      lastName
      role
      phoneNumber
      phoneNumberNote
      emailAddress
      emailAddressNote
    }
    documents(first: 100) {
      edges {
        node {
          ...CaseDetailServiceDocument
        }
      }
      totalCount
    }
  }
  ${CaseDetailServiceDocumentFragmentDoc}
`
export const CaseDetailCaseDocumentFragmentDoc = gql`
  fragment CaseDetailCaseDocument on CaseDocument {
    id
    type
    otherType
    ...CaseDetailDocument
  }
  ${CaseDetailDocumentFragmentDoc}
`
export const CaseEventFragmentDoc = gql`
  fragment CaseEvent on CaseEvent {
    id
    type
    date
    parameters
    actionResult {
      title
    }
    actionType {
      title
    }
    createdByApp {
      name
    }
    createdByUser {
      id
      displayName
    }
    user {
      id
      displayName
      email
    }
    service {
      id
      title
    }
  }
`
export const CustomerAddressFragmentDoc = gql`
  fragment CustomerAddress on CustomerAddress {
    id
    city
    cityArea
    country {
      code
      name
    }
    countryArea {
      id
      name
    }
    note
    postalCode
    streetAddress1
    streetAddress2
    isPermanentResidency
    isDefault
  }
`
export const CustomerPhoneFragmentDoc = gql`
  fragment CustomerPhone on CustomerContactPhone {
    id
    phoneNumber
    note
    isDefault
  }
`
export const CustomerEmailFragmentDoc = gql`
  fragment CustomerEmail on CustomerContactEmail {
    id
    emailAddress
    note
    isDefault
  }
`
export const CaseDetailCustomerDocumentFragmentDoc = gql`
  fragment CaseDetailCustomerDocument on CustomerDocument {
    id
    type
    otherType
    ...CaseDetailDocument
  }
  ${CaseDetailDocumentFragmentDoc}
`
export const UserInfoFragmentDoc = gql`
  fragment UserInfo on User {
    id
    email
    displayName
  }
`
export const CaseDetailFeeFragmentDoc = gql`
  fragment CaseDetailFee on CaseFee {
    id
    paymentStatus
    feeRate
    requestedAmount {
      amount
      currency
    }
    paidAmount {
      amount
      currency
    }
    remainingAmount {
      amount
      currency
    }
    createdAt
    payments {
      id
      paidAt
      paidAmount {
        amount
        currency
      }
    }
  }
`
export const CaseActionResultOptionFragmentDoc = gql`
  fragment CaseActionResultOption on CaseActionResult {
    id
    title
  }
`
export const CaseActionTypeOptionFragmentDoc = gql`
  fragment CaseActionTypeOption on CaseActionType {
    id
    title
  }
`
export const CaseCloseReasonOptionFragmentDoc = gql`
  fragment CaseCloseReasonOption on CaseCloseReason {
    id
    title
  }
`
export const ServiceTypeOptionFragmentDoc = gql`
  fragment ServiceTypeOption on ServiceType {
    id
    title
  }
`
export const CaseDetailFragmentDoc = gql`
  fragment CaseDetail on Case {
    id
    __typename
    createdAt
    modifiedAt
    type
    status
    customStatus
    closeReason {
      id
      title
    }
    title
    actions {
      ...CaseAction
    }
    services {
      ...CaseService
    }
    documents(first: 100) {
      edges {
        node {
          ...CaseDetailCaseDocument
        }
      }
      totalCount
    }
    events(last: 250) {
      edges {
        node {
          ...CaseEvent
        }
      }
    }
    customer {
      id
      displayName
      birthDate
      gender
      governmentId
      educationLevel
      marriageStatus
      defaultPhone {
        id
        phoneNumber
      }
      defaultEmail {
        id
        emailAddress
      }
      addresses {
        ...CustomerAddress
      }
      contactPhones {
        ...CustomerPhone
      }
      contactEmails {
        ...CustomerEmail
      }
      documents(first: 100) {
        edges {
          node {
            ...CaseDetailCustomerDocument
          }
        }
        totalCount
      }
      note
    }
    address {
      ...CustomerAddress
    }
    contactEmail {
      ...CustomerEmail
    }
    contactPhone {
      ...CustomerPhone
    }
    assignedTo {
      ...UserInfo
    }
    totalDebt {
      amount
      currency
    }
    defaultFeeRate
    fee {
      ...CaseDetailFee
    }
    availableCaseActionResults {
      ...CaseActionResultOption
    }
    availableCaseActionTypes {
      ...CaseActionTypeOption
    }
    availableCaseCloseReasons {
      ...CaseCloseReasonOption
    }
    availableServiceTypes {
      ...ServiceTypeOption
    }
    createdByUser {
      id
      displayName
    }
    createdByApp {
      id
      name
    }
  }
  ${CaseActionFragmentDoc}
  ${CaseServiceFragmentDoc}
  ${CaseDetailCaseDocumentFragmentDoc}
  ${CaseEventFragmentDoc}
  ${CustomerAddressFragmentDoc}
  ${CustomerPhoneFragmentDoc}
  ${CustomerEmailFragmentDoc}
  ${CaseDetailCustomerDocumentFragmentDoc}
  ${UserInfoFragmentDoc}
  ${CaseDetailFeeFragmentDoc}
  ${CaseActionResultOptionFragmentDoc}
  ${CaseActionTypeOptionFragmentDoc}
  ${CaseCloseReasonOptionFragmentDoc}
  ${ServiceTypeOptionFragmentDoc}
`
export const CaseListFragmentDoc = gql`
  fragment CaseList on Case {
    id
    createdAt
    id
    title
    customStatus
    status
    closeReason {
      id
      title
    }
    fee {
      paymentStatus
    }
    customer {
      displayName
    }
    assignedTo {
      displayName
    }
  }
`
export const CaseServiceProviderFragmentDoc = gql`
  fragment CaseServiceProvider on ServiceProvider {
    id
    name
  }
`
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`
export const CustomerDetailFragmentDoc = gql`
  fragment CustomerDetail on Customer {
    id
    firstName
    middleName
    lastName
    displayName
    gender
    marriageStatus
    educationLevel
    governmentId
    birthDate
    addresses {
      ...CustomerAddress
      cases {
        totalCount
      }
    }
    contactPhones {
      ...CustomerPhone
      cases {
        totalCount
      }
    }
    contactEmails {
      ...CustomerEmail
      cases {
        totalCount
      }
    }
  }
  ${CustomerAddressFragmentDoc}
  ${CustomerPhoneFragmentDoc}
  ${CustomerEmailFragmentDoc}
`
export const CustomerDetailCaseFragmentDoc = gql`
  fragment CustomerDetailCase on Case {
    id
    title
    status
    assignedTo {
      displayName
    }
    closeReason {
      title
    }
  }
`
export const CustomerDetailServiceFragmentDoc = gql`
  fragment CustomerDetailService on Service {
    id
    title
    status
    case {
      id
      status
      assignedTo {
        displayName
      }
      closeReason {
        title
      }
    }
  }
`
export const CustomerListFragmentDoc = gql`
  fragment CustomerList on Customer {
    id
    firstName
    middleName
    lastName
    displayName
    createdAt
    cases {
      totalCount
    }
  }
`
export const ServiceProviderAvailableServiceTypeFragmentDoc = gql`
  fragment ServiceProviderAvailableServiceType on ServiceType {
    id
    value
    title
  }
`
export const ServiceProviderListFragmentDoc = gql`
  fragment ServiceProviderList on ServiceProvider {
    id
    name
    createdAt
  }
`
export const ServiceProviderDetailFragmentDoc = gql`
  fragment ServiceProviderDetail on ServiceProvider {
    ...ServiceProviderList
    businessName
    serviceTypes {
      id
      title
    }
  }
  ${ServiceProviderListFragmentDoc}
`
export const ServiceProviderDetailContactFragmentDoc = gql`
  fragment ServiceProviderDetailContact on ServiceProviderContact {
    id
    displayName
    role
    phoneNumber
    emailAddress
    createdAt
  }
`
export const UserTeamListFragmentDoc = gql`
  fragment UserTeamList on UserTeam {
    id
    name
    types
    businessEntities {
      name
    }
    serviceTypes {
      title
    }
  }
`
export const UserTeamDetailFragmentDoc = gql`
  fragment UserTeamDetail on UserTeam {
    ...UserTeamList
    businessEntities {
      id
      name
    }
    serviceTypes {
      id
      title
    }
    users {
      displayName
    }
  }
  ${UserTeamListFragmentDoc}
`
export const UserListFragmentDoc = gql`
  fragment UserList on User {
    id
    email
    firstName
    isActive
    lastName
    roles
    userTeam {
      id
      name
    }
    roleDescription
  }
`
export const UserDetailFragmentDoc = gql`
  fragment UserDetail on User {
    ...UserList
    displayName
    accountPermissions {
      code
      name
    }
    isSuperUser
    avatar(size: 120) {
      url
    }
    signature {
      ...RemoteFileInfo
    }
  }
  ${UserListFragmentDoc}
  ${RemoteFileInfoFragmentDoc}
`
export const AppCreateDocument = gql`
  mutation AppCreate($input: AppCreateInput!) {
    appCreate(input: $input) {
      app {
        ...App
      }
    }
  }
  ${AppFragmentDoc}
`
export type AppCreateMutationFn = Apollo.MutationFunction<
  Types.AppCreateMutation,
  Types.AppCreateMutationVariables
>

/**
 * __useAppCreateMutation__
 *
 * To run a mutation, you first call `useAppCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appCreateMutation, { data, loading, error }] = useAppCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AppCreateMutation,
    Types.AppCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.AppCreateMutation,
    Types.AppCreateMutationVariables
  >(AppCreateDocument, options)
}
export type AppCreateMutationHookResult = ReturnType<
  typeof useAppCreateMutation
>
export type AppCreateMutationResult =
  Apollo.MutationResult<Types.AppCreateMutation>
export type AppCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.AppCreateMutation,
  Types.AppCreateMutationVariables
>
export const AppDeleteDocument = gql`
  mutation AppDelete($id: ID!) {
    appDelete(id: $id) {
      app {
        ...App
      }
    }
  }
  ${AppFragmentDoc}
`
export type AppDeleteMutationFn = Apollo.MutationFunction<
  Types.AppDeleteMutation,
  Types.AppDeleteMutationVariables
>

/**
 * __useAppDeleteMutation__
 *
 * To run a mutation, you first call `useAppDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appDeleteMutation, { data, loading, error }] = useAppDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AppDeleteMutation,
    Types.AppDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.AppDeleteMutation,
    Types.AppDeleteMutationVariables
  >(AppDeleteDocument, options)
}
export type AppDeleteMutationHookResult = ReturnType<
  typeof useAppDeleteMutation
>
export type AppDeleteMutationResult =
  Apollo.MutationResult<Types.AppDeleteMutation>
export type AppDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.AppDeleteMutation,
  Types.AppDeleteMutationVariables
>
export const AppTokenCreateDocument = gql`
  mutation AppTokenCreate($id: ID!, $input: AppTokenCreateInput!) {
    appTokenCreate(id: $id, input: $input) {
      token
      appToken {
        name
        id
      }
    }
  }
`
export type AppTokenCreateMutationFn = Apollo.MutationFunction<
  Types.AppTokenCreateMutation,
  Types.AppTokenCreateMutationVariables
>

/**
 * __useAppTokenCreateMutation__
 *
 * To run a mutation, you first call `useAppTokenCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppTokenCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appTokenCreateMutation, { data, loading, error }] = useAppTokenCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppTokenCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AppTokenCreateMutation,
    Types.AppTokenCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.AppTokenCreateMutation,
    Types.AppTokenCreateMutationVariables
  >(AppTokenCreateDocument, options)
}
export type AppTokenCreateMutationHookResult = ReturnType<
  typeof useAppTokenCreateMutation
>
export type AppTokenCreateMutationResult =
  Apollo.MutationResult<Types.AppTokenCreateMutation>
export type AppTokenCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.AppTokenCreateMutation,
  Types.AppTokenCreateMutationVariables
>
export const AppTokenDeleteDocument = gql`
  mutation AppTokenDelete($id: ID!) {
    appTokenDelete(id: $id) {
      appToken {
        name
        id
      }
    }
  }
`
export type AppTokenDeleteMutationFn = Apollo.MutationFunction<
  Types.AppTokenDeleteMutation,
  Types.AppTokenDeleteMutationVariables
>

/**
 * __useAppTokenDeleteMutation__
 *
 * To run a mutation, you first call `useAppTokenDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppTokenDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appTokenDeleteMutation, { data, loading, error }] = useAppTokenDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppTokenDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AppTokenDeleteMutation,
    Types.AppTokenDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.AppTokenDeleteMutation,
    Types.AppTokenDeleteMutationVariables
  >(AppTokenDeleteDocument, options)
}
export type AppTokenDeleteMutationHookResult = ReturnType<
  typeof useAppTokenDeleteMutation
>
export type AppTokenDeleteMutationResult =
  Apollo.MutationResult<Types.AppTokenDeleteMutation>
export type AppTokenDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.AppTokenDeleteMutation,
  Types.AppTokenDeleteMutationVariables
>
export const AppUpdateDocument = gql`
  mutation AppUpdate($id: ID!, $input: AppUpdateInput!) {
    appUpdate(id: $id, input: $input) {
      app {
        ...App
      }
    }
  }
  ${AppFragmentDoc}
`
export type AppUpdateMutationFn = Apollo.MutationFunction<
  Types.AppUpdateMutation,
  Types.AppUpdateMutationVariables
>

/**
 * __useAppUpdateMutation__
 *
 * To run a mutation, you first call `useAppUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appUpdateMutation, { data, loading, error }] = useAppUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AppUpdateMutation,
    Types.AppUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.AppUpdateMutation,
    Types.AppUpdateMutationVariables
  >(AppUpdateDocument, options)
}
export type AppUpdateMutationHookResult = ReturnType<
  typeof useAppUpdateMutation
>
export type AppUpdateMutationResult =
  Apollo.MutationResult<Types.AppUpdateMutation>
export type AppUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.AppUpdateMutation,
  Types.AppUpdateMutationVariables
>
export const AppCreatePermissionsDocument = gql`
  query AppCreatePermissions {
    permissions {
      code
      name
    }
  }
`

/**
 * __useAppCreatePermissionsQuery__
 *
 * To run a query within a React component, call `useAppCreatePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppCreatePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppCreatePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppCreatePermissionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AppCreatePermissionsQuery,
    Types.AppCreatePermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.AppCreatePermissionsQuery,
    Types.AppCreatePermissionsQueryVariables
  >(AppCreatePermissionsDocument, options)
}
export function useAppCreatePermissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AppCreatePermissionsQuery,
    Types.AppCreatePermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.AppCreatePermissionsQuery,
    Types.AppCreatePermissionsQueryVariables
  >(AppCreatePermissionsDocument, options)
}
export function useAppCreatePermissionsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.AppCreatePermissionsQuery,
        Types.AppCreatePermissionsQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.AppCreatePermissionsQuery,
    Types.AppCreatePermissionsQueryVariables
  >(AppCreatePermissionsDocument, options)
}
export type AppCreatePermissionsQueryHookResult = ReturnType<
  typeof useAppCreatePermissionsQuery
>
export type AppCreatePermissionsLazyQueryHookResult = ReturnType<
  typeof useAppCreatePermissionsLazyQuery
>
export type AppCreatePermissionsSuspenseQueryHookResult = ReturnType<
  typeof useAppCreatePermissionsSuspenseQuery
>
export type AppCreatePermissionsQueryResult = Apollo.QueryResult<
  Types.AppCreatePermissionsQuery,
  Types.AppCreatePermissionsQueryVariables
>
export const AppDetailDocument = gql`
  query AppDetail($id: ID!) {
    app(id: $id) {
      ...App
    }
    permissions {
      code
      name
    }
  }
  ${AppFragmentDoc}
`

/**
 * __useAppDetailQuery__
 *
 * To run a query within a React component, call `useAppDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.AppDetailQuery,
    Types.AppDetailQueryVariables
  > &
    (
      | { variables: Types.AppDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.AppDetailQuery,
    Types.AppDetailQueryVariables
  >(AppDetailDocument, options)
}
export function useAppDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AppDetailQuery,
    Types.AppDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.AppDetailQuery,
    Types.AppDetailQueryVariables
  >(AppDetailDocument, options)
}
export function useAppDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.AppDetailQuery,
        Types.AppDetailQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.AppDetailQuery,
    Types.AppDetailQueryVariables
  >(AppDetailDocument, options)
}
export type AppDetailQueryHookResult = ReturnType<typeof useAppDetailQuery>
export type AppDetailLazyQueryHookResult = ReturnType<
  typeof useAppDetailLazyQuery
>
export type AppDetailSuspenseQueryHookResult = ReturnType<
  typeof useAppDetailSuspenseQuery
>
export type AppDetailQueryResult = Apollo.QueryResult<
  Types.AppDetailQuery,
  Types.AppDetailQueryVariables
>
export const AppListDocument = gql`
  query AppList(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sort: AppsSortInput
    $filter: AppsFilterInput
  ) {
    apps(
      before: $before
      after: $after
      first: $first
      last: $last
      sort: $sort
      filter: $filter
    ) {
      edges {
        node {
          id
          name
          isActive
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useAppListQuery__
 *
 * To run a query within a React component, call `useAppListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppListQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAppListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AppListQuery,
    Types.AppListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.AppListQuery,
    Types.AppListQueryVariables
  >(AppListDocument, options)
}
export function useAppListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AppListQuery,
    Types.AppListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.AppListQuery,
    Types.AppListQueryVariables
  >(AppListDocument, options)
}
export function useAppListSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.AppListQuery,
        Types.AppListQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.AppListQuery,
    Types.AppListQueryVariables
  >(AppListDocument, options)
}
export type AppListQueryHookResult = ReturnType<typeof useAppListQuery>
export type AppListLazyQueryHookResult = ReturnType<typeof useAppListLazyQuery>
export type AppListSuspenseQueryHookResult = ReturnType<
  typeof useAppListSuspenseQuery
>
export type AppListQueryResult = Apollo.QueryResult<
  Types.AppListQuery,
  Types.AppListQueryVariables
>
export const ConfirmAccountDocument = gql`
  mutation ConfirmAccount(
    $email: String!
    $password: String!
    $token: String!
  ) {
    confirmAccount(email: $email, password: $password, token: $token) {
      refreshToken
      accessToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`
export type ConfirmAccountMutationFn = Apollo.MutationFunction<
  Types.ConfirmAccountMutation,
  Types.ConfirmAccountMutationVariables
>

/**
 * __useConfirmAccountMutation__
 *
 * To run a mutation, you first call `useConfirmAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAccountMutation, { data, loading, error }] = useConfirmAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ConfirmAccountMutation,
    Types.ConfirmAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ConfirmAccountMutation,
    Types.ConfirmAccountMutationVariables
  >(ConfirmAccountDocument, options)
}
export type ConfirmAccountMutationHookResult = ReturnType<
  typeof useConfirmAccountMutation
>
export type ConfirmAccountMutationResult =
  Apollo.MutationResult<Types.ConfirmAccountMutation>
export type ConfirmAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.ConfirmAccountMutation,
  Types.ConfirmAccountMutationVariables
>
export const RefreshTokenDocument = gql`
  mutation RefreshToken($token: String!) {
    tokenRefresh(refreshToken: $token) {
      token
    }
  }
`
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  Types.RefreshTokenMutation,
  Types.RefreshTokenMutationVariables
>

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RefreshTokenMutation,
    Types.RefreshTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.RefreshTokenMutation,
    Types.RefreshTokenMutationVariables
  >(RefreshTokenDocument, options)
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>
export type RefreshTokenMutationResult =
  Apollo.MutationResult<Types.RefreshTokenMutation>
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  Types.RefreshTokenMutation,
  Types.RefreshTokenMutationVariables
>
export const RequestPasswordResetDocument = gql`
  mutation RequestPasswordReset($email: String!, $redirectUrl: String!) {
    requestPasswordReset(email: $email, redirectUrl: $redirectUrl)
  }
`
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<
  Types.RequestPasswordResetMutation,
  Types.RequestPasswordResetMutationVariables
>

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RequestPasswordResetMutation,
    Types.RequestPasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.RequestPasswordResetMutation,
    Types.RequestPasswordResetMutationVariables
  >(RequestPasswordResetDocument, options)
}
export type RequestPasswordResetMutationHookResult = ReturnType<
  typeof useRequestPasswordResetMutation
>
export type RequestPasswordResetMutationResult =
  Apollo.MutationResult<Types.RequestPasswordResetMutation>
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  Types.RequestPasswordResetMutation,
  Types.RequestPasswordResetMutationVariables
>
export const RequestedPasswordSetDocument = gql`
  mutation RequestedPasswordSet(
    $email: String!
    $password: String!
    $token: String!
  ) {
    requestedPasswordSet(email: $email, password: $password, token: $token) {
      refreshToken
      accessToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`
export type RequestedPasswordSetMutationFn = Apollo.MutationFunction<
  Types.RequestedPasswordSetMutation,
  Types.RequestedPasswordSetMutationVariables
>

/**
 * __useRequestedPasswordSetMutation__
 *
 * To run a mutation, you first call `useRequestedPasswordSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestedPasswordSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestedPasswordSetMutation, { data, loading, error }] = useRequestedPasswordSetMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRequestedPasswordSetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RequestedPasswordSetMutation,
    Types.RequestedPasswordSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.RequestedPasswordSetMutation,
    Types.RequestedPasswordSetMutationVariables
  >(RequestedPasswordSetDocument, options)
}
export type RequestedPasswordSetMutationHookResult = ReturnType<
  typeof useRequestedPasswordSetMutation
>
export type RequestedPasswordSetMutationResult =
  Apollo.MutationResult<Types.RequestedPasswordSetMutation>
export type RequestedPasswordSetMutationOptions = Apollo.BaseMutationOptions<
  Types.RequestedPasswordSetMutation,
  Types.RequestedPasswordSetMutationVariables
>
export const TokenAuthDocument = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenCreate(email: $email, password: $password) {
      accessToken
      refreshToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`
export type TokenAuthMutationFn = Apollo.MutationFunction<
  Types.TokenAuthMutation,
  Types.TokenAuthMutationVariables
>

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.TokenAuthMutation,
    Types.TokenAuthMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.TokenAuthMutation,
    Types.TokenAuthMutationVariables
  >(TokenAuthDocument, options)
}
export type TokenAuthMutationHookResult = ReturnType<
  typeof useTokenAuthMutation
>
export type TokenAuthMutationResult =
  Apollo.MutationResult<Types.TokenAuthMutation>
export type TokenAuthMutationOptions = Apollo.BaseMutationOptions<
  Types.TokenAuthMutation,
  Types.TokenAuthMutationVariables
>
export const VerifyTokenDocument = gql`
  mutation VerifyToken($token: String!) {
    tokenVerifyUser(token: $token) {
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`
export type VerifyTokenMutationFn = Apollo.MutationFunction<
  Types.VerifyTokenMutation,
  Types.VerifyTokenMutationVariables
>

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.VerifyTokenMutation,
    Types.VerifyTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.VerifyTokenMutation,
    Types.VerifyTokenMutationVariables
  >(VerifyTokenDocument, options)
}
export type VerifyTokenMutationHookResult = ReturnType<
  typeof useVerifyTokenMutation
>
export type VerifyTokenMutationResult =
  Apollo.MutationResult<Types.VerifyTokenMutation>
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<
  Types.VerifyTokenMutation,
  Types.VerifyTokenMutationVariables
>
export const BusinessEntityAddDocument = gql`
  mutation BusinessEntityAdd($input: BusinessEntityCreateInput!) {
    businessEntityCreate(input: $input) {
      businessEntity {
        ...BusinessEntityDetail
      }
    }
  }
  ${BusinessEntityDetailFragmentDoc}
`
export type BusinessEntityAddMutationFn = Apollo.MutationFunction<
  Types.BusinessEntityAddMutation,
  Types.BusinessEntityAddMutationVariables
>

/**
 * __useBusinessEntityAddMutation__
 *
 * To run a mutation, you first call `useBusinessEntityAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusinessEntityAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [businessEntityAddMutation, { data, loading, error }] = useBusinessEntityAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusinessEntityAddMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.BusinessEntityAddMutation,
    Types.BusinessEntityAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.BusinessEntityAddMutation,
    Types.BusinessEntityAddMutationVariables
  >(BusinessEntityAddDocument, options)
}
export type BusinessEntityAddMutationHookResult = ReturnType<
  typeof useBusinessEntityAddMutation
>
export type BusinessEntityAddMutationResult =
  Apollo.MutationResult<Types.BusinessEntityAddMutation>
export type BusinessEntityAddMutationOptions = Apollo.BaseMutationOptions<
  Types.BusinessEntityAddMutation,
  Types.BusinessEntityAddMutationVariables
>
export const BusinessEntityDeleteDocument = gql`
  mutation BusinessEntityDelete($id: ID!) {
    businessEntityDelete(id: $id) {
      __typename
    }
  }
`
export type BusinessEntityDeleteMutationFn = Apollo.MutationFunction<
  Types.BusinessEntityDeleteMutation,
  Types.BusinessEntityDeleteMutationVariables
>

/**
 * __useBusinessEntityDeleteMutation__
 *
 * To run a mutation, you first call `useBusinessEntityDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusinessEntityDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [businessEntityDeleteMutation, { data, loading, error }] = useBusinessEntityDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessEntityDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.BusinessEntityDeleteMutation,
    Types.BusinessEntityDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.BusinessEntityDeleteMutation,
    Types.BusinessEntityDeleteMutationVariables
  >(BusinessEntityDeleteDocument, options)
}
export type BusinessEntityDeleteMutationHookResult = ReturnType<
  typeof useBusinessEntityDeleteMutation
>
export type BusinessEntityDeleteMutationResult =
  Apollo.MutationResult<Types.BusinessEntityDeleteMutation>
export type BusinessEntityDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.BusinessEntityDeleteMutation,
  Types.BusinessEntityDeleteMutationVariables
>
export const BusinessEntityUpdateDocument = gql`
  mutation BusinessEntityUpdate($id: ID!, $input: BusinessEntityUpdateInput!) {
    businessEntityUpdate(id: $id, input: $input) {
      businessEntity {
        ...BusinessEntityDetail
      }
    }
  }
  ${BusinessEntityDetailFragmentDoc}
`
export type BusinessEntityUpdateMutationFn = Apollo.MutationFunction<
  Types.BusinessEntityUpdateMutation,
  Types.BusinessEntityUpdateMutationVariables
>

/**
 * __useBusinessEntityUpdateMutation__
 *
 * To run a mutation, you first call `useBusinessEntityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusinessEntityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [businessEntityUpdateMutation, { data, loading, error }] = useBusinessEntityUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusinessEntityUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.BusinessEntityUpdateMutation,
    Types.BusinessEntityUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.BusinessEntityUpdateMutation,
    Types.BusinessEntityUpdateMutationVariables
  >(BusinessEntityUpdateDocument, options)
}
export type BusinessEntityUpdateMutationHookResult = ReturnType<
  typeof useBusinessEntityUpdateMutation
>
export type BusinessEntityUpdateMutationResult =
  Apollo.MutationResult<Types.BusinessEntityUpdateMutation>
export type BusinessEntityUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.BusinessEntityUpdateMutation,
  Types.BusinessEntityUpdateMutationVariables
>
export const BusinessEntityDetailDocument = gql`
  query BusinessEntityDetail($id: ID!) {
    businessEntity(id: $id) {
      ...BusinessEntityDetail
    }
  }
  ${BusinessEntityDetailFragmentDoc}
`

/**
 * __useBusinessEntityDetailQuery__
 *
 * To run a query within a React component, call `useBusinessEntityDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessEntityDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessEntityDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessEntityDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.BusinessEntityDetailQuery,
    Types.BusinessEntityDetailQueryVariables
  > &
    (
      | { variables: Types.BusinessEntityDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.BusinessEntityDetailQuery,
    Types.BusinessEntityDetailQueryVariables
  >(BusinessEntityDetailDocument, options)
}
export function useBusinessEntityDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BusinessEntityDetailQuery,
    Types.BusinessEntityDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.BusinessEntityDetailQuery,
    Types.BusinessEntityDetailQueryVariables
  >(BusinessEntityDetailDocument, options)
}
export function useBusinessEntityDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.BusinessEntityDetailQuery,
        Types.BusinessEntityDetailQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.BusinessEntityDetailQuery,
    Types.BusinessEntityDetailQueryVariables
  >(BusinessEntityDetailDocument, options)
}
export type BusinessEntityDetailQueryHookResult = ReturnType<
  typeof useBusinessEntityDetailQuery
>
export type BusinessEntityDetailLazyQueryHookResult = ReturnType<
  typeof useBusinessEntityDetailLazyQuery
>
export type BusinessEntityDetailSuspenseQueryHookResult = ReturnType<
  typeof useBusinessEntityDetailSuspenseQuery
>
export type BusinessEntityDetailQueryResult = Apollo.QueryResult<
  Types.BusinessEntityDetailQuery,
  Types.BusinessEntityDetailQueryVariables
>
export const BusinessEntityListDocument = gql`
  query BusinessEntityList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: BusinessEntitiesFilterInput
    $sort: BusinessEntitiesSortInput
  ) {
    businessEntities(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
      sort: $sort
    ) {
      edges {
        cursor
        node {
          ...BusinessEntityList
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${BusinessEntityListFragmentDoc}
`

/**
 * __useBusinessEntityListQuery__
 *
 * To run a query within a React component, call `useBusinessEntityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessEntityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessEntityListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useBusinessEntityListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BusinessEntityListQuery,
    Types.BusinessEntityListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.BusinessEntityListQuery,
    Types.BusinessEntityListQueryVariables
  >(BusinessEntityListDocument, options)
}
export function useBusinessEntityListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BusinessEntityListQuery,
    Types.BusinessEntityListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.BusinessEntityListQuery,
    Types.BusinessEntityListQueryVariables
  >(BusinessEntityListDocument, options)
}
export function useBusinessEntityListSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.BusinessEntityListQuery,
        Types.BusinessEntityListQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.BusinessEntityListQuery,
    Types.BusinessEntityListQueryVariables
  >(BusinessEntityListDocument, options)
}
export type BusinessEntityListQueryHookResult = ReturnType<
  typeof useBusinessEntityListQuery
>
export type BusinessEntityListLazyQueryHookResult = ReturnType<
  typeof useBusinessEntityListLazyQuery
>
export type BusinessEntityListSuspenseQueryHookResult = ReturnType<
  typeof useBusinessEntityListSuspenseQuery
>
export type BusinessEntityListQueryResult = Apollo.QueryResult<
  Types.BusinessEntityListQuery,
  Types.BusinessEntityListQueryVariables
>
export const CaseActionResultCreateDocument = gql`
  mutation CaseActionResultCreate($input: CaseActionResultCreateInput!) {
    create: caseActionResultCreate(input: $input) {
      newSettingsItem: caseActionResult {
        id
      }
    }
  }
`
export type CaseActionResultCreateMutationFn = Apollo.MutationFunction<
  Types.CaseActionResultCreateMutation,
  Types.CaseActionResultCreateMutationVariables
>

/**
 * __useCaseActionResultCreateMutation__
 *
 * To run a mutation, you first call `useCaseActionResultCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseActionResultCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseActionResultCreateMutation, { data, loading, error }] = useCaseActionResultCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseActionResultCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseActionResultCreateMutation,
    Types.CaseActionResultCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseActionResultCreateMutation,
    Types.CaseActionResultCreateMutationVariables
  >(CaseActionResultCreateDocument, options)
}
export type CaseActionResultCreateMutationHookResult = ReturnType<
  typeof useCaseActionResultCreateMutation
>
export type CaseActionResultCreateMutationResult =
  Apollo.MutationResult<Types.CaseActionResultCreateMutation>
export type CaseActionResultCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseActionResultCreateMutation,
  Types.CaseActionResultCreateMutationVariables
>
export const CaseActionResultDeleteDocument = gql`
  mutation CaseActionResultDelete($id: ID!) {
    delete: caseActionResultDelete(id: $id) {
      deletedSettingsItem: caseActionResult {
        id
      }
    }
  }
`
export type CaseActionResultDeleteMutationFn = Apollo.MutationFunction<
  Types.CaseActionResultDeleteMutation,
  Types.CaseActionResultDeleteMutationVariables
>

/**
 * __useCaseActionResultDeleteMutation__
 *
 * To run a mutation, you first call `useCaseActionResultDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseActionResultDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseActionResultDeleteMutation, { data, loading, error }] = useCaseActionResultDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseActionResultDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseActionResultDeleteMutation,
    Types.CaseActionResultDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseActionResultDeleteMutation,
    Types.CaseActionResultDeleteMutationVariables
  >(CaseActionResultDeleteDocument, options)
}
export type CaseActionResultDeleteMutationHookResult = ReturnType<
  typeof useCaseActionResultDeleteMutation
>
export type CaseActionResultDeleteMutationResult =
  Apollo.MutationResult<Types.CaseActionResultDeleteMutation>
export type CaseActionResultDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseActionResultDeleteMutation,
  Types.CaseActionResultDeleteMutationVariables
>
export const CaseActionResultUpdateDocument = gql`
  mutation CaseActionResultUpdate(
    $id: ID!
    $input: CaseActionResultUpdateInput!
  ) {
    update: caseActionResultUpdate(id: $id, input: $input) {
      updatedSettingsItem: caseActionResult {
        id
      }
    }
  }
`
export type CaseActionResultUpdateMutationFn = Apollo.MutationFunction<
  Types.CaseActionResultUpdateMutation,
  Types.CaseActionResultUpdateMutationVariables
>

/**
 * __useCaseActionResultUpdateMutation__
 *
 * To run a mutation, you first call `useCaseActionResultUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseActionResultUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseActionResultUpdateMutation, { data, loading, error }] = useCaseActionResultUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseActionResultUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseActionResultUpdateMutation,
    Types.CaseActionResultUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseActionResultUpdateMutation,
    Types.CaseActionResultUpdateMutationVariables
  >(CaseActionResultUpdateDocument, options)
}
export type CaseActionResultUpdateMutationHookResult = ReturnType<
  typeof useCaseActionResultUpdateMutation
>
export type CaseActionResultUpdateMutationResult =
  Apollo.MutationResult<Types.CaseActionResultUpdateMutation>
export type CaseActionResultUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseActionResultUpdateMutation,
  Types.CaseActionResultUpdateMutationVariables
>
export const CaseActionTypeCreateDocument = gql`
  mutation CaseActionTypeCreate($input: CaseActionTypeCreateInput!) {
    create: caseActionTypeCreate(input: $input) {
      __typename
      newSettingsItem: caseActionType {
        __typename
        id
      }
    }
  }
`
export type CaseActionTypeCreateMutationFn = Apollo.MutationFunction<
  Types.CaseActionTypeCreateMutation,
  Types.CaseActionTypeCreateMutationVariables
>

/**
 * __useCaseActionTypeCreateMutation__
 *
 * To run a mutation, you first call `useCaseActionTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseActionTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseActionTypeCreateMutation, { data, loading, error }] = useCaseActionTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseActionTypeCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseActionTypeCreateMutation,
    Types.CaseActionTypeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseActionTypeCreateMutation,
    Types.CaseActionTypeCreateMutationVariables
  >(CaseActionTypeCreateDocument, options)
}
export type CaseActionTypeCreateMutationHookResult = ReturnType<
  typeof useCaseActionTypeCreateMutation
>
export type CaseActionTypeCreateMutationResult =
  Apollo.MutationResult<Types.CaseActionTypeCreateMutation>
export type CaseActionTypeCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseActionTypeCreateMutation,
  Types.CaseActionTypeCreateMutationVariables
>
export const CaseActionTypeDeleteDocument = gql`
  mutation CaseActionTypeDelete($id: ID!) {
    delete: caseActionTypeDelete(id: $id) {
      deletedSettingsItem: caseActionType {
        id
      }
    }
  }
`
export type CaseActionTypeDeleteMutationFn = Apollo.MutationFunction<
  Types.CaseActionTypeDeleteMutation,
  Types.CaseActionTypeDeleteMutationVariables
>

/**
 * __useCaseActionTypeDeleteMutation__
 *
 * To run a mutation, you first call `useCaseActionTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseActionTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseActionTypeDeleteMutation, { data, loading, error }] = useCaseActionTypeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseActionTypeDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseActionTypeDeleteMutation,
    Types.CaseActionTypeDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseActionTypeDeleteMutation,
    Types.CaseActionTypeDeleteMutationVariables
  >(CaseActionTypeDeleteDocument, options)
}
export type CaseActionTypeDeleteMutationHookResult = ReturnType<
  typeof useCaseActionTypeDeleteMutation
>
export type CaseActionTypeDeleteMutationResult =
  Apollo.MutationResult<Types.CaseActionTypeDeleteMutation>
export type CaseActionTypeDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseActionTypeDeleteMutation,
  Types.CaseActionTypeDeleteMutationVariables
>
export const CaseActionTypeUpdateDocument = gql`
  mutation CaseActionTypeUpdate($id: ID!, $input: CaseActionTypeUpdateInput!) {
    update: caseActionTypeUpdate(id: $id, input: $input) {
      updatedSettingsItem: caseActionType {
        id
      }
    }
  }
`
export type CaseActionTypeUpdateMutationFn = Apollo.MutationFunction<
  Types.CaseActionTypeUpdateMutation,
  Types.CaseActionTypeUpdateMutationVariables
>

/**
 * __useCaseActionTypeUpdateMutation__
 *
 * To run a mutation, you first call `useCaseActionTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseActionTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseActionTypeUpdateMutation, { data, loading, error }] = useCaseActionTypeUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseActionTypeUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseActionTypeUpdateMutation,
    Types.CaseActionTypeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseActionTypeUpdateMutation,
    Types.CaseActionTypeUpdateMutationVariables
  >(CaseActionTypeUpdateDocument, options)
}
export type CaseActionTypeUpdateMutationHookResult = ReturnType<
  typeof useCaseActionTypeUpdateMutation
>
export type CaseActionTypeUpdateMutationResult =
  Apollo.MutationResult<Types.CaseActionTypeUpdateMutation>
export type CaseActionTypeUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseActionTypeUpdateMutation,
  Types.CaseActionTypeUpdateMutationVariables
>
export const CaseCloseReasonCreateDocument = gql`
  mutation CaseCloseReasonCreate($input: CaseCloseReasonCreateInput!) {
    create: caseCloseReasonCreate(input: $input) {
      newSettingsItem: caseCloseReason {
        id
      }
    }
  }
`
export type CaseCloseReasonCreateMutationFn = Apollo.MutationFunction<
  Types.CaseCloseReasonCreateMutation,
  Types.CaseCloseReasonCreateMutationVariables
>

/**
 * __useCaseCloseReasonCreateMutation__
 *
 * To run a mutation, you first call `useCaseCloseReasonCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseCloseReasonCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseCloseReasonCreateMutation, { data, loading, error }] = useCaseCloseReasonCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseCloseReasonCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseCloseReasonCreateMutation,
    Types.CaseCloseReasonCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseCloseReasonCreateMutation,
    Types.CaseCloseReasonCreateMutationVariables
  >(CaseCloseReasonCreateDocument, options)
}
export type CaseCloseReasonCreateMutationHookResult = ReturnType<
  typeof useCaseCloseReasonCreateMutation
>
export type CaseCloseReasonCreateMutationResult =
  Apollo.MutationResult<Types.CaseCloseReasonCreateMutation>
export type CaseCloseReasonCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseCloseReasonCreateMutation,
  Types.CaseCloseReasonCreateMutationVariables
>
export const CaseCloseReasonDeleteDocument = gql`
  mutation CaseCloseReasonDelete($id: ID!) {
    delete: caseCloseReasonDelete(id: $id) {
      deletedSettingsItem: caseCloseReason {
        id
      }
    }
  }
`
export type CaseCloseReasonDeleteMutationFn = Apollo.MutationFunction<
  Types.CaseCloseReasonDeleteMutation,
  Types.CaseCloseReasonDeleteMutationVariables
>

/**
 * __useCaseCloseReasonDeleteMutation__
 *
 * To run a mutation, you first call `useCaseCloseReasonDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseCloseReasonDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseCloseReasonDeleteMutation, { data, loading, error }] = useCaseCloseReasonDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseCloseReasonDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseCloseReasonDeleteMutation,
    Types.CaseCloseReasonDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseCloseReasonDeleteMutation,
    Types.CaseCloseReasonDeleteMutationVariables
  >(CaseCloseReasonDeleteDocument, options)
}
export type CaseCloseReasonDeleteMutationHookResult = ReturnType<
  typeof useCaseCloseReasonDeleteMutation
>
export type CaseCloseReasonDeleteMutationResult =
  Apollo.MutationResult<Types.CaseCloseReasonDeleteMutation>
export type CaseCloseReasonDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseCloseReasonDeleteMutation,
  Types.CaseCloseReasonDeleteMutationVariables
>
export const CaseCloseReasonUpdateDocument = gql`
  mutation CaseCloseReasonUpdate(
    $id: ID!
    $input: CaseCloseReasonUpdateInput!
  ) {
    update: caseCloseReasonUpdate(id: $id, input: $input) {
      updatedSettingsItem: caseCloseReason {
        id
      }
    }
  }
`
export type CaseCloseReasonUpdateMutationFn = Apollo.MutationFunction<
  Types.CaseCloseReasonUpdateMutation,
  Types.CaseCloseReasonUpdateMutationVariables
>

/**
 * __useCaseCloseReasonUpdateMutation__
 *
 * To run a mutation, you first call `useCaseCloseReasonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseCloseReasonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseCloseReasonUpdateMutation, { data, loading, error }] = useCaseCloseReasonUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseCloseReasonUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseCloseReasonUpdateMutation,
    Types.CaseCloseReasonUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseCloseReasonUpdateMutation,
    Types.CaseCloseReasonUpdateMutationVariables
  >(CaseCloseReasonUpdateDocument, options)
}
export type CaseCloseReasonUpdateMutationHookResult = ReturnType<
  typeof useCaseCloseReasonUpdateMutation
>
export type CaseCloseReasonUpdateMutationResult =
  Apollo.MutationResult<Types.CaseCloseReasonUpdateMutation>
export type CaseCloseReasonUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseCloseReasonUpdateMutation,
  Types.CaseCloseReasonUpdateMutationVariables
>
export const ServiceTypeCreateDocument = gql`
  mutation ServiceTypeCreate($input: ServiceTypeCreateInput!) {
    create: serviceTypeCreate(input: $input) {
      newSettingsItem: serviceType {
        id
      }
    }
  }
`
export type ServiceTypeCreateMutationFn = Apollo.MutationFunction<
  Types.ServiceTypeCreateMutation,
  Types.ServiceTypeCreateMutationVariables
>

/**
 * __useServiceTypeCreateMutation__
 *
 * To run a mutation, you first call `useServiceTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceTypeCreateMutation, { data, loading, error }] = useServiceTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceTypeCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceTypeCreateMutation,
    Types.ServiceTypeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceTypeCreateMutation,
    Types.ServiceTypeCreateMutationVariables
  >(ServiceTypeCreateDocument, options)
}
export type ServiceTypeCreateMutationHookResult = ReturnType<
  typeof useServiceTypeCreateMutation
>
export type ServiceTypeCreateMutationResult =
  Apollo.MutationResult<Types.ServiceTypeCreateMutation>
export type ServiceTypeCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceTypeCreateMutation,
  Types.ServiceTypeCreateMutationVariables
>
export const ServiceTypeDeleteDocument = gql`
  mutation ServiceTypeDelete($id: ID!) {
    delete: serviceTypeDelete(id: $id) {
      deletedSettingsItem: serviceType {
        id
      }
    }
  }
`
export type ServiceTypeDeleteMutationFn = Apollo.MutationFunction<
  Types.ServiceTypeDeleteMutation,
  Types.ServiceTypeDeleteMutationVariables
>

/**
 * __useServiceTypeDeleteMutation__
 *
 * To run a mutation, you first call `useServiceTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceTypeDeleteMutation, { data, loading, error }] = useServiceTypeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceTypeDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceTypeDeleteMutation,
    Types.ServiceTypeDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceTypeDeleteMutation,
    Types.ServiceTypeDeleteMutationVariables
  >(ServiceTypeDeleteDocument, options)
}
export type ServiceTypeDeleteMutationHookResult = ReturnType<
  typeof useServiceTypeDeleteMutation
>
export type ServiceTypeDeleteMutationResult =
  Apollo.MutationResult<Types.ServiceTypeDeleteMutation>
export type ServiceTypeDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceTypeDeleteMutation,
  Types.ServiceTypeDeleteMutationVariables
>
export const ServiceTypeUpdateDocument = gql`
  mutation ServiceTypeUpdate($id: ID!, $input: ServiceTypeUpdateInput!) {
    update: serviceTypeUpdate(id: $id, input: $input) {
      updatedSettingsItem: serviceType {
        id
      }
    }
  }
`
export type ServiceTypeUpdateMutationFn = Apollo.MutationFunction<
  Types.ServiceTypeUpdateMutation,
  Types.ServiceTypeUpdateMutationVariables
>

/**
 * __useServiceTypeUpdateMutation__
 *
 * To run a mutation, you first call `useServiceTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceTypeUpdateMutation, { data, loading, error }] = useServiceTypeUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceTypeUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceTypeUpdateMutation,
    Types.ServiceTypeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceTypeUpdateMutation,
    Types.ServiceTypeUpdateMutationVariables
  >(ServiceTypeUpdateDocument, options)
}
export type ServiceTypeUpdateMutationHookResult = ReturnType<
  typeof useServiceTypeUpdateMutation
>
export type ServiceTypeUpdateMutationResult =
  Apollo.MutationResult<Types.ServiceTypeUpdateMutation>
export type ServiceTypeUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceTypeUpdateMutation,
  Types.ServiceTypeUpdateMutationVariables
>
export const CaseActionResultDetailDocument = gql`
  query CaseActionResultDetail($id: ID!) {
    caseActionResult(id: $id) {
      id
      isActive
      value
      title
      createdAt
    }
  }
`

/**
 * __useCaseActionResultDetailQuery__
 *
 * To run a query within a React component, call `useCaseActionResultDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseActionResultDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseActionResultDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseActionResultDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.CaseActionResultDetailQuery,
    Types.CaseActionResultDetailQueryVariables
  > &
    (
      | {
          variables: Types.CaseActionResultDetailQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CaseActionResultDetailQuery,
    Types.CaseActionResultDetailQueryVariables
  >(CaseActionResultDetailDocument, options)
}
export function useCaseActionResultDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CaseActionResultDetailQuery,
    Types.CaseActionResultDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CaseActionResultDetailQuery,
    Types.CaseActionResultDetailQueryVariables
  >(CaseActionResultDetailDocument, options)
}
export function useCaseActionResultDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CaseActionResultDetailQuery,
        Types.CaseActionResultDetailQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CaseActionResultDetailQuery,
    Types.CaseActionResultDetailQueryVariables
  >(CaseActionResultDetailDocument, options)
}
export type CaseActionResultDetailQueryHookResult = ReturnType<
  typeof useCaseActionResultDetailQuery
>
export type CaseActionResultDetailLazyQueryHookResult = ReturnType<
  typeof useCaseActionResultDetailLazyQuery
>
export type CaseActionResultDetailSuspenseQueryHookResult = ReturnType<
  typeof useCaseActionResultDetailSuspenseQuery
>
export type CaseActionResultDetailQueryResult = Apollo.QueryResult<
  Types.CaseActionResultDetailQuery,
  Types.CaseActionResultDetailQueryVariables
>
export const CaseActionResultListDocument = gql`
  query CaseActionResultList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: CaseActionResultsSortInput
  ) {
    caseActionResults(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: { deactivated: true }
      sort: $sort
    ) {
      edges {
        node {
          id
          isActive
          value
          title
          createdAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

/**
 * __useCaseActionResultListQuery__
 *
 * To run a query within a React component, call `useCaseActionResultListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseActionResultListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseActionResultListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCaseActionResultListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CaseActionResultListQuery,
    Types.CaseActionResultListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CaseActionResultListQuery,
    Types.CaseActionResultListQueryVariables
  >(CaseActionResultListDocument, options)
}
export function useCaseActionResultListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CaseActionResultListQuery,
    Types.CaseActionResultListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CaseActionResultListQuery,
    Types.CaseActionResultListQueryVariables
  >(CaseActionResultListDocument, options)
}
export function useCaseActionResultListSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CaseActionResultListQuery,
        Types.CaseActionResultListQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CaseActionResultListQuery,
    Types.CaseActionResultListQueryVariables
  >(CaseActionResultListDocument, options)
}
export type CaseActionResultListQueryHookResult = ReturnType<
  typeof useCaseActionResultListQuery
>
export type CaseActionResultListLazyQueryHookResult = ReturnType<
  typeof useCaseActionResultListLazyQuery
>
export type CaseActionResultListSuspenseQueryHookResult = ReturnType<
  typeof useCaseActionResultListSuspenseQuery
>
export type CaseActionResultListQueryResult = Apollo.QueryResult<
  Types.CaseActionResultListQuery,
  Types.CaseActionResultListQueryVariables
>
export const CaseActionTypeDetailDocument = gql`
  query CaseActionTypeDetail($id: ID!) {
    caseActionType(id: $id) {
      id
      isActive
      value
      title
      createdAt
    }
  }
`

/**
 * __useCaseActionTypeDetailQuery__
 *
 * To run a query within a React component, call `useCaseActionTypeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseActionTypeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseActionTypeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseActionTypeDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.CaseActionTypeDetailQuery,
    Types.CaseActionTypeDetailQueryVariables
  > &
    (
      | { variables: Types.CaseActionTypeDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CaseActionTypeDetailQuery,
    Types.CaseActionTypeDetailQueryVariables
  >(CaseActionTypeDetailDocument, options)
}
export function useCaseActionTypeDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CaseActionTypeDetailQuery,
    Types.CaseActionTypeDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CaseActionTypeDetailQuery,
    Types.CaseActionTypeDetailQueryVariables
  >(CaseActionTypeDetailDocument, options)
}
export function useCaseActionTypeDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CaseActionTypeDetailQuery,
        Types.CaseActionTypeDetailQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CaseActionTypeDetailQuery,
    Types.CaseActionTypeDetailQueryVariables
  >(CaseActionTypeDetailDocument, options)
}
export type CaseActionTypeDetailQueryHookResult = ReturnType<
  typeof useCaseActionTypeDetailQuery
>
export type CaseActionTypeDetailLazyQueryHookResult = ReturnType<
  typeof useCaseActionTypeDetailLazyQuery
>
export type CaseActionTypeDetailSuspenseQueryHookResult = ReturnType<
  typeof useCaseActionTypeDetailSuspenseQuery
>
export type CaseActionTypeDetailQueryResult = Apollo.QueryResult<
  Types.CaseActionTypeDetailQuery,
  Types.CaseActionTypeDetailQueryVariables
>
export const CaseActionTypeListDocument = gql`
  query CaseActionTypeList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: CaseActionTypesSortInput
  ) {
    caseActionTypes(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: { deactivated: true }
      sort: $sort
    ) {
      edges {
        node {
          id
          isActive
          value
          title
          createdAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

/**
 * __useCaseActionTypeListQuery__
 *
 * To run a query within a React component, call `useCaseActionTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseActionTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseActionTypeListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCaseActionTypeListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CaseActionTypeListQuery,
    Types.CaseActionTypeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CaseActionTypeListQuery,
    Types.CaseActionTypeListQueryVariables
  >(CaseActionTypeListDocument, options)
}
export function useCaseActionTypeListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CaseActionTypeListQuery,
    Types.CaseActionTypeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CaseActionTypeListQuery,
    Types.CaseActionTypeListQueryVariables
  >(CaseActionTypeListDocument, options)
}
export function useCaseActionTypeListSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CaseActionTypeListQuery,
        Types.CaseActionTypeListQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CaseActionTypeListQuery,
    Types.CaseActionTypeListQueryVariables
  >(CaseActionTypeListDocument, options)
}
export type CaseActionTypeListQueryHookResult = ReturnType<
  typeof useCaseActionTypeListQuery
>
export type CaseActionTypeListLazyQueryHookResult = ReturnType<
  typeof useCaseActionTypeListLazyQuery
>
export type CaseActionTypeListSuspenseQueryHookResult = ReturnType<
  typeof useCaseActionTypeListSuspenseQuery
>
export type CaseActionTypeListQueryResult = Apollo.QueryResult<
  Types.CaseActionTypeListQuery,
  Types.CaseActionTypeListQueryVariables
>
export const CaseCloseReasonDetailDocument = gql`
  query CaseCloseReasonDetail($id: ID!) {
    caseCloseReason(id: $id) {
      id
      isActive
      value
      title
      createdAt
    }
  }
`

/**
 * __useCaseCloseReasonDetailQuery__
 *
 * To run a query within a React component, call `useCaseCloseReasonDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseCloseReasonDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseCloseReasonDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseCloseReasonDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.CaseCloseReasonDetailQuery,
    Types.CaseCloseReasonDetailQueryVariables
  > &
    (
      | { variables: Types.CaseCloseReasonDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CaseCloseReasonDetailQuery,
    Types.CaseCloseReasonDetailQueryVariables
  >(CaseCloseReasonDetailDocument, options)
}
export function useCaseCloseReasonDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CaseCloseReasonDetailQuery,
    Types.CaseCloseReasonDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CaseCloseReasonDetailQuery,
    Types.CaseCloseReasonDetailQueryVariables
  >(CaseCloseReasonDetailDocument, options)
}
export function useCaseCloseReasonDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CaseCloseReasonDetailQuery,
        Types.CaseCloseReasonDetailQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CaseCloseReasonDetailQuery,
    Types.CaseCloseReasonDetailQueryVariables
  >(CaseCloseReasonDetailDocument, options)
}
export type CaseCloseReasonDetailQueryHookResult = ReturnType<
  typeof useCaseCloseReasonDetailQuery
>
export type CaseCloseReasonDetailLazyQueryHookResult = ReturnType<
  typeof useCaseCloseReasonDetailLazyQuery
>
export type CaseCloseReasonDetailSuspenseQueryHookResult = ReturnType<
  typeof useCaseCloseReasonDetailSuspenseQuery
>
export type CaseCloseReasonDetailQueryResult = Apollo.QueryResult<
  Types.CaseCloseReasonDetailQuery,
  Types.CaseCloseReasonDetailQueryVariables
>
export const CaseCloseReasonListDocument = gql`
  query CaseCloseReasonList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: CaseCloseReasonsSortInput
  ) {
    caseCloseReasons(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: { deactivated: true }
      sort: $sort
    ) {
      edges {
        node {
          id
          isActive
          value
          title
          createdAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

/**
 * __useCaseCloseReasonListQuery__
 *
 * To run a query within a React component, call `useCaseCloseReasonListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseCloseReasonListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseCloseReasonListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCaseCloseReasonListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CaseCloseReasonListQuery,
    Types.CaseCloseReasonListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CaseCloseReasonListQuery,
    Types.CaseCloseReasonListQueryVariables
  >(CaseCloseReasonListDocument, options)
}
export function useCaseCloseReasonListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CaseCloseReasonListQuery,
    Types.CaseCloseReasonListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CaseCloseReasonListQuery,
    Types.CaseCloseReasonListQueryVariables
  >(CaseCloseReasonListDocument, options)
}
export function useCaseCloseReasonListSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CaseCloseReasonListQuery,
        Types.CaseCloseReasonListQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CaseCloseReasonListQuery,
    Types.CaseCloseReasonListQueryVariables
  >(CaseCloseReasonListDocument, options)
}
export type CaseCloseReasonListQueryHookResult = ReturnType<
  typeof useCaseCloseReasonListQuery
>
export type CaseCloseReasonListLazyQueryHookResult = ReturnType<
  typeof useCaseCloseReasonListLazyQuery
>
export type CaseCloseReasonListSuspenseQueryHookResult = ReturnType<
  typeof useCaseCloseReasonListSuspenseQuery
>
export type CaseCloseReasonListQueryResult = Apollo.QueryResult<
  Types.CaseCloseReasonListQuery,
  Types.CaseCloseReasonListQueryVariables
>
export const ServiceTypeDetailDocument = gql`
  query ServiceTypeDetail($id: ID!) {
    serviceType(id: $id) {
      id
      isActive
      value
      title
      createdAt
      localizedTitle
    }
  }
`

/**
 * __useServiceTypeDetailQuery__
 *
 * To run a query within a React component, call `useServiceTypeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceTypeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceTypeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceTypeDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.ServiceTypeDetailQuery,
    Types.ServiceTypeDetailQueryVariables
  > &
    (
      | { variables: Types.ServiceTypeDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.ServiceTypeDetailQuery,
    Types.ServiceTypeDetailQueryVariables
  >(ServiceTypeDetailDocument, options)
}
export function useServiceTypeDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ServiceTypeDetailQuery,
    Types.ServiceTypeDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.ServiceTypeDetailQuery,
    Types.ServiceTypeDetailQueryVariables
  >(ServiceTypeDetailDocument, options)
}
export function useServiceTypeDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.ServiceTypeDetailQuery,
        Types.ServiceTypeDetailQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.ServiceTypeDetailQuery,
    Types.ServiceTypeDetailQueryVariables
  >(ServiceTypeDetailDocument, options)
}
export type ServiceTypeDetailQueryHookResult = ReturnType<
  typeof useServiceTypeDetailQuery
>
export type ServiceTypeDetailLazyQueryHookResult = ReturnType<
  typeof useServiceTypeDetailLazyQuery
>
export type ServiceTypeDetailSuspenseQueryHookResult = ReturnType<
  typeof useServiceTypeDetailSuspenseQuery
>
export type ServiceTypeDetailQueryResult = Apollo.QueryResult<
  Types.ServiceTypeDetailQuery,
  Types.ServiceTypeDetailQueryVariables
>
export const ServiceTypeListDocument = gql`
  query ServiceTypeList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: ServiceTypesSortInput
  ) {
    serviceTypes(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: { deactivated: true }
      sort: $sort
    ) {
      edges {
        node {
          id
          isActive
          value
          title
          createdAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

/**
 * __useServiceTypeListQuery__
 *
 * To run a query within a React component, call `useServiceTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceTypeListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useServiceTypeListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ServiceTypeListQuery,
    Types.ServiceTypeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.ServiceTypeListQuery,
    Types.ServiceTypeListQueryVariables
  >(ServiceTypeListDocument, options)
}
export function useServiceTypeListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ServiceTypeListQuery,
    Types.ServiceTypeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.ServiceTypeListQuery,
    Types.ServiceTypeListQueryVariables
  >(ServiceTypeListDocument, options)
}
export function useServiceTypeListSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.ServiceTypeListQuery,
        Types.ServiceTypeListQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.ServiceTypeListQuery,
    Types.ServiceTypeListQueryVariables
  >(ServiceTypeListDocument, options)
}
export type ServiceTypeListQueryHookResult = ReturnType<
  typeof useServiceTypeListQuery
>
export type ServiceTypeListLazyQueryHookResult = ReturnType<
  typeof useServiceTypeListLazyQuery
>
export type ServiceTypeListSuspenseQueryHookResult = ReturnType<
  typeof useServiceTypeListSuspenseQuery
>
export type ServiceTypeListQueryResult = Apollo.QueryResult<
  Types.ServiceTypeListQuery,
  Types.ServiceTypeListQueryVariables
>
export const CaseActionCreateDocument = gql`
  mutation CaseActionCreate($caseId: ID!, $input: CaseActionCreateInput!) {
    caseActionCreate(caseId: $caseId, input: $input) {
      caseAction {
        result {
          id
          title
        }
        type {
          id
          title
        }
        case {
          ...CaseDetail
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type CaseActionCreateMutationFn = Apollo.MutationFunction<
  Types.CaseActionCreateMutation,
  Types.CaseActionCreateMutationVariables
>

/**
 * __useCaseActionCreateMutation__
 *
 * To run a mutation, you first call `useCaseActionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseActionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseActionCreateMutation, { data, loading, error }] = useCaseActionCreateMutation({
 *   variables: {
 *      caseId: // value for 'caseId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseActionCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseActionCreateMutation,
    Types.CaseActionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseActionCreateMutation,
    Types.CaseActionCreateMutationVariables
  >(CaseActionCreateDocument, options)
}
export type CaseActionCreateMutationHookResult = ReturnType<
  typeof useCaseActionCreateMutation
>
export type CaseActionCreateMutationResult =
  Apollo.MutationResult<Types.CaseActionCreateMutation>
export type CaseActionCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseActionCreateMutation,
  Types.CaseActionCreateMutationVariables
>
export const CaseActionDeleteDocument = gql`
  mutation CaseActionDelete($id: ID!) {
    caseActionDelete(id: $id) {
      caseAction {
        result {
          id
          title
        }
        type {
          id
          title
        }
        case {
          ...CaseDetail
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type CaseActionDeleteMutationFn = Apollo.MutationFunction<
  Types.CaseActionDeleteMutation,
  Types.CaseActionDeleteMutationVariables
>

/**
 * __useCaseActionDeleteMutation__
 *
 * To run a mutation, you first call `useCaseActionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseActionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseActionDeleteMutation, { data, loading, error }] = useCaseActionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseActionDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseActionDeleteMutation,
    Types.CaseActionDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseActionDeleteMutation,
    Types.CaseActionDeleteMutationVariables
  >(CaseActionDeleteDocument, options)
}
export type CaseActionDeleteMutationHookResult = ReturnType<
  typeof useCaseActionDeleteMutation
>
export type CaseActionDeleteMutationResult =
  Apollo.MutationResult<Types.CaseActionDeleteMutation>
export type CaseActionDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseActionDeleteMutation,
  Types.CaseActionDeleteMutationVariables
>
export const CaseActionUpdateDocument = gql`
  mutation CaseActionUpdate($id: ID!, $input: CaseActionUpdateInput!) {
    caseActionUpdate(id: $id, input: $input) {
      caseAction {
        result {
          id
          title
        }
        type {
          id
          title
        }
        case {
          ...CaseDetail
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type CaseActionUpdateMutationFn = Apollo.MutationFunction<
  Types.CaseActionUpdateMutation,
  Types.CaseActionUpdateMutationVariables
>

/**
 * __useCaseActionUpdateMutation__
 *
 * To run a mutation, you first call `useCaseActionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseActionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseActionUpdateMutation, { data, loading, error }] = useCaseActionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseActionUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseActionUpdateMutation,
    Types.CaseActionUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseActionUpdateMutation,
    Types.CaseActionUpdateMutationVariables
  >(CaseActionUpdateDocument, options)
}
export type CaseActionUpdateMutationHookResult = ReturnType<
  typeof useCaseActionUpdateMutation
>
export type CaseActionUpdateMutationResult =
  Apollo.MutationResult<Types.CaseActionUpdateMutation>
export type CaseActionUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseActionUpdateMutation,
  Types.CaseActionUpdateMutationVariables
>
export const CaseAddressAssignDocument = gql`
  mutation CaseAddressAssign($id: ID!, $input: CaseAddressAssignInput!) {
    caseAddressAssign(id: $id, input: $input) {
      case {
        ...CaseDetail
        customer {
          addresses {
            ...CustomerAddress
          }
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
  ${CustomerAddressFragmentDoc}
`
export type CaseAddressAssignMutationFn = Apollo.MutationFunction<
  Types.CaseAddressAssignMutation,
  Types.CaseAddressAssignMutationVariables
>

/**
 * __useCaseAddressAssignMutation__
 *
 * To run a mutation, you first call `useCaseAddressAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseAddressAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseAddressAssignMutation, { data, loading, error }] = useCaseAddressAssignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseAddressAssignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseAddressAssignMutation,
    Types.CaseAddressAssignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseAddressAssignMutation,
    Types.CaseAddressAssignMutationVariables
  >(CaseAddressAssignDocument, options)
}
export type CaseAddressAssignMutationHookResult = ReturnType<
  typeof useCaseAddressAssignMutation
>
export type CaseAddressAssignMutationResult =
  Apollo.MutationResult<Types.CaseAddressAssignMutation>
export type CaseAddressAssignMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseAddressAssignMutation,
  Types.CaseAddressAssignMutationVariables
>
export const CaseAddressAssignNewDocument = gql`
  mutation CaseAddressAssignNew($id: ID!, $input: CaseAddressAssignNewInput!) {
    caseAddressAssignNew(id: $id, input: $input) {
      case {
        ...CaseDetail
        customer {
          addresses {
            ...CustomerAddress
          }
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
  ${CustomerAddressFragmentDoc}
`
export type CaseAddressAssignNewMutationFn = Apollo.MutationFunction<
  Types.CaseAddressAssignNewMutation,
  Types.CaseAddressAssignNewMutationVariables
>

/**
 * __useCaseAddressAssignNewMutation__
 *
 * To run a mutation, you first call `useCaseAddressAssignNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseAddressAssignNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseAddressAssignNewMutation, { data, loading, error }] = useCaseAddressAssignNewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseAddressAssignNewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseAddressAssignNewMutation,
    Types.CaseAddressAssignNewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseAddressAssignNewMutation,
    Types.CaseAddressAssignNewMutationVariables
  >(CaseAddressAssignNewDocument, options)
}
export type CaseAddressAssignNewMutationHookResult = ReturnType<
  typeof useCaseAddressAssignNewMutation
>
export type CaseAddressAssignNewMutationResult =
  Apollo.MutationResult<Types.CaseAddressAssignNewMutation>
export type CaseAddressAssignNewMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseAddressAssignNewMutation,
  Types.CaseAddressAssignNewMutationVariables
>
export const CaseAssignDocument = gql`
  mutation CaseAssign($id: ID!, $input: CaseAssignInput!) {
    caseAssign(id: $id, input: $input) {
      case {
        ...CaseDetail
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type CaseAssignMutationFn = Apollo.MutationFunction<
  Types.CaseAssignMutation,
  Types.CaseAssignMutationVariables
>

/**
 * __useCaseAssignMutation__
 *
 * To run a mutation, you first call `useCaseAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseAssignMutation, { data, loading, error }] = useCaseAssignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseAssignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseAssignMutation,
    Types.CaseAssignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseAssignMutation,
    Types.CaseAssignMutationVariables
  >(CaseAssignDocument, options)
}
export type CaseAssignMutationHookResult = ReturnType<
  typeof useCaseAssignMutation
>
export type CaseAssignMutationResult =
  Apollo.MutationResult<Types.CaseAssignMutation>
export type CaseAssignMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseAssignMutation,
  Types.CaseAssignMutationVariables
>
export const CaseAssignSelfDocument = gql`
  mutation CaseAssignSelf($id: ID!) {
    caseAssignSelf(id: $id) {
      case {
        ...CaseDetail
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type CaseAssignSelfMutationFn = Apollo.MutationFunction<
  Types.CaseAssignSelfMutation,
  Types.CaseAssignSelfMutationVariables
>

/**
 * __useCaseAssignSelfMutation__
 *
 * To run a mutation, you first call `useCaseAssignSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseAssignSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseAssignSelfMutation, { data, loading, error }] = useCaseAssignSelfMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseAssignSelfMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseAssignSelfMutation,
    Types.CaseAssignSelfMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseAssignSelfMutation,
    Types.CaseAssignSelfMutationVariables
  >(CaseAssignSelfDocument, options)
}
export type CaseAssignSelfMutationHookResult = ReturnType<
  typeof useCaseAssignSelfMutation
>
export type CaseAssignSelfMutationResult =
  Apollo.MutationResult<Types.CaseAssignSelfMutation>
export type CaseAssignSelfMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseAssignSelfMutation,
  Types.CaseAssignSelfMutationVariables
>
export const CaseBulkAssignDocument = gql`
  mutation CaseBulkAssign($input: CaseBulkAssignInput!) {
    caseBulkAssign(input: $input) {
      cases {
        id
        assignedTo {
          id
          displayName
        }
        status
      }
    }
  }
`
export type CaseBulkAssignMutationFn = Apollo.MutationFunction<
  Types.CaseBulkAssignMutation,
  Types.CaseBulkAssignMutationVariables
>

/**
 * __useCaseBulkAssignMutation__
 *
 * To run a mutation, you first call `useCaseBulkAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseBulkAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseBulkAssignMutation, { data, loading, error }] = useCaseBulkAssignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseBulkAssignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseBulkAssignMutation,
    Types.CaseBulkAssignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseBulkAssignMutation,
    Types.CaseBulkAssignMutationVariables
  >(CaseBulkAssignDocument, options)
}
export type CaseBulkAssignMutationHookResult = ReturnType<
  typeof useCaseBulkAssignMutation
>
export type CaseBulkAssignMutationResult =
  Apollo.MutationResult<Types.CaseBulkAssignMutation>
export type CaseBulkAssignMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseBulkAssignMutation,
  Types.CaseBulkAssignMutationVariables
>
export const CaseBulkReopenDocument = gql`
  mutation CaseBulkReopen($input: CaseBulkReopenInput!) {
    caseBulkReopen(input: $input) {
      cases {
        id
        status
      }
    }
  }
`
export type CaseBulkReopenMutationFn = Apollo.MutationFunction<
  Types.CaseBulkReopenMutation,
  Types.CaseBulkReopenMutationVariables
>

/**
 * __useCaseBulkReopenMutation__
 *
 * To run a mutation, you first call `useCaseBulkReopenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseBulkReopenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseBulkReopenMutation, { data, loading, error }] = useCaseBulkReopenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseBulkReopenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseBulkReopenMutation,
    Types.CaseBulkReopenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseBulkReopenMutation,
    Types.CaseBulkReopenMutationVariables
  >(CaseBulkReopenDocument, options)
}
export type CaseBulkReopenMutationHookResult = ReturnType<
  typeof useCaseBulkReopenMutation
>
export type CaseBulkReopenMutationResult =
  Apollo.MutationResult<Types.CaseBulkReopenMutation>
export type CaseBulkReopenMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseBulkReopenMutation,
  Types.CaseBulkReopenMutationVariables
>
export const CaseCloseDocument = gql`
  mutation CaseClose($id: ID!, $input: CaseCloseInput!) {
    caseClose(id: $id, input: $input) {
      case {
        ...CaseDetail
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type CaseCloseMutationFn = Apollo.MutationFunction<
  Types.CaseCloseMutation,
  Types.CaseCloseMutationVariables
>

/**
 * __useCaseCloseMutation__
 *
 * To run a mutation, you first call `useCaseCloseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseCloseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseCloseMutation, { data, loading, error }] = useCaseCloseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseCloseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseCloseMutation,
    Types.CaseCloseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseCloseMutation,
    Types.CaseCloseMutationVariables
  >(CaseCloseDocument, options)
}
export type CaseCloseMutationHookResult = ReturnType<
  typeof useCaseCloseMutation
>
export type CaseCloseMutationResult =
  Apollo.MutationResult<Types.CaseCloseMutation>
export type CaseCloseMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseCloseMutation,
  Types.CaseCloseMutationVariables
>
export const CaseCreateDocument = gql`
  mutation CaseCreate(
    $input: CaseCreateInput!
    $skipDuplicatesCheck: Boolean!
    $assignToSelf: Boolean!
  ) {
    caseCreate(
      input: $input
      skipDuplicatesCheck: $skipDuplicatesCheck
      assignToSelf: $assignToSelf
    ) {
      case {
        id
      }
    }
  }
`
export type CaseCreateMutationFn = Apollo.MutationFunction<
  Types.CaseCreateMutation,
  Types.CaseCreateMutationVariables
>

/**
 * __useCaseCreateMutation__
 *
 * To run a mutation, you first call `useCaseCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseCreateMutation, { data, loading, error }] = useCaseCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      skipDuplicatesCheck: // value for 'skipDuplicatesCheck'
 *      assignToSelf: // value for 'assignToSelf'
 *   },
 * });
 */
export function useCaseCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseCreateMutation,
    Types.CaseCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseCreateMutation,
    Types.CaseCreateMutationVariables
  >(CaseCreateDocument, options)
}
export type CaseCreateMutationHookResult = ReturnType<
  typeof useCaseCreateMutation
>
export type CaseCreateMutationResult =
  Apollo.MutationResult<Types.CaseCreateMutation>
export type CaseCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseCreateMutation,
  Types.CaseCreateMutationVariables
>
export const CaseCustomStatusUpdateDocument = gql`
  mutation CaseCustomStatusUpdate(
    $id: ID!
    $input: CaseCustomStatusUpdateInput!
  ) {
    caseCustomStatusUpdate(id: $id, input: $input) {
      case {
        id
        customStatus
        events(last: 250) {
          edges {
            node {
              ...CaseEvent
            }
          }
        }
      }
    }
  }
  ${CaseEventFragmentDoc}
`
export type CaseCustomStatusUpdateMutationFn = Apollo.MutationFunction<
  Types.CaseCustomStatusUpdateMutation,
  Types.CaseCustomStatusUpdateMutationVariables
>

/**
 * __useCaseCustomStatusUpdateMutation__
 *
 * To run a mutation, you first call `useCaseCustomStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseCustomStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseCustomStatusUpdateMutation, { data, loading, error }] = useCaseCustomStatusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseCustomStatusUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseCustomStatusUpdateMutation,
    Types.CaseCustomStatusUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseCustomStatusUpdateMutation,
    Types.CaseCustomStatusUpdateMutationVariables
  >(CaseCustomStatusUpdateDocument, options)
}
export type CaseCustomStatusUpdateMutationHookResult = ReturnType<
  typeof useCaseCustomStatusUpdateMutation
>
export type CaseCustomStatusUpdateMutationResult =
  Apollo.MutationResult<Types.CaseCustomStatusUpdateMutation>
export type CaseCustomStatusUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseCustomStatusUpdateMutation,
  Types.CaseCustomStatusUpdateMutationVariables
>
export const CaseDocumentCreateDocument = gql`
  mutation CaseDocumentCreate($caseId: ID!, $input: CaseDocumentCreateInput!) {
    caseDocumentCreate(id: $caseId, input: $input) {
      caseDocument {
        id
        file {
          fileName
        }
        case {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailDocument
              }
            }
            totalCount
          }
          events(last: 250) {
            edges {
              node {
                ...CaseEvent
              }
            }
          }
        }
      }
    }
  }
  ${CaseDetailDocumentFragmentDoc}
  ${CaseEventFragmentDoc}
`
export type CaseDocumentCreateMutationFn = Apollo.MutationFunction<
  Types.CaseDocumentCreateMutation,
  Types.CaseDocumentCreateMutationVariables
>

/**
 * __useCaseDocumentCreateMutation__
 *
 * To run a mutation, you first call `useCaseDocumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseDocumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseDocumentCreateMutation, { data, loading, error }] = useCaseDocumentCreateMutation({
 *   variables: {
 *      caseId: // value for 'caseId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseDocumentCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseDocumentCreateMutation,
    Types.CaseDocumentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseDocumentCreateMutation,
    Types.CaseDocumentCreateMutationVariables
  >(CaseDocumentCreateDocument, options)
}
export type CaseDocumentCreateMutationHookResult = ReturnType<
  typeof useCaseDocumentCreateMutation
>
export type CaseDocumentCreateMutationResult =
  Apollo.MutationResult<Types.CaseDocumentCreateMutation>
export type CaseDocumentCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseDocumentCreateMutation,
  Types.CaseDocumentCreateMutationVariables
>
export const CaseDocumentDeleteDocument = gql`
  mutation CaseDocumentDelete($id: ID!) {
    caseDocumentDelete(id: $id) {
      caseDocument {
        id
        file {
          fileName
        }
        case {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailDocument
              }
            }
            totalCount
          }
          events(last: 250) {
            edges {
              node {
                ...CaseEvent
              }
            }
          }
        }
      }
    }
  }
  ${CaseDetailDocumentFragmentDoc}
  ${CaseEventFragmentDoc}
`
export type CaseDocumentDeleteMutationFn = Apollo.MutationFunction<
  Types.CaseDocumentDeleteMutation,
  Types.CaseDocumentDeleteMutationVariables
>

/**
 * __useCaseDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useCaseDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseDocumentDeleteMutation, { data, loading, error }] = useCaseDocumentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseDocumentDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseDocumentDeleteMutation,
    Types.CaseDocumentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseDocumentDeleteMutation,
    Types.CaseDocumentDeleteMutationVariables
  >(CaseDocumentDeleteDocument, options)
}
export type CaseDocumentDeleteMutationHookResult = ReturnType<
  typeof useCaseDocumentDeleteMutation
>
export type CaseDocumentDeleteMutationResult =
  Apollo.MutationResult<Types.CaseDocumentDeleteMutation>
export type CaseDocumentDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseDocumentDeleteMutation,
  Types.CaseDocumentDeleteMutationVariables
>
export const CaseDocumentGenerateInvoiceForFeeDocument = gql`
  mutation CaseDocumentGenerateInvoiceForFee(
    $id: ID!
    $input: CaseDocumentGenerateInvoiceForFeeInput!
  ) {
    caseDocumentGenerateInvoiceForFee(id: $id, input: $input) {
      caseDocument {
        file {
          url
        }
        case {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailCaseDocument
              }
            }
            totalCount
          }
        }
      }
    }
  }
  ${CaseDetailCaseDocumentFragmentDoc}
`
export type CaseDocumentGenerateInvoiceForFeeMutationFn =
  Apollo.MutationFunction<
    Types.CaseDocumentGenerateInvoiceForFeeMutation,
    Types.CaseDocumentGenerateInvoiceForFeeMutationVariables
  >

/**
 * __useCaseDocumentGenerateInvoiceForFeeMutation__
 *
 * To run a mutation, you first call `useCaseDocumentGenerateInvoiceForFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseDocumentGenerateInvoiceForFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseDocumentGenerateInvoiceForFeeMutation, { data, loading, error }] = useCaseDocumentGenerateInvoiceForFeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseDocumentGenerateInvoiceForFeeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseDocumentGenerateInvoiceForFeeMutation,
    Types.CaseDocumentGenerateInvoiceForFeeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseDocumentGenerateInvoiceForFeeMutation,
    Types.CaseDocumentGenerateInvoiceForFeeMutationVariables
  >(CaseDocumentGenerateInvoiceForFeeDocument, options)
}
export type CaseDocumentGenerateInvoiceForFeeMutationHookResult = ReturnType<
  typeof useCaseDocumentGenerateInvoiceForFeeMutation
>
export type CaseDocumentGenerateInvoiceForFeeMutationResult =
  Apollo.MutationResult<Types.CaseDocumentGenerateInvoiceForFeeMutation>
export type CaseDocumentGenerateInvoiceForFeeMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CaseDocumentGenerateInvoiceForFeeMutation,
    Types.CaseDocumentGenerateInvoiceForFeeMutationVariables
  >
export const CaseDocumentGeneratePtpLetterDocument = gql`
  mutation CaseDocumentGeneratePTPLetter(
    $id: ID!
    $input: CaseDocumentGeneratePTPLetterInput!
  ) {
    caseDocumentGeneratePTPLetter(id: $id, input: $input) {
      caseDocument {
        file {
          url
        }
        case {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailCaseDocument
              }
            }
            totalCount
          }
        }
      }
    }
  }
  ${CaseDetailCaseDocumentFragmentDoc}
`
export type CaseDocumentGeneratePtpLetterMutationFn = Apollo.MutationFunction<
  Types.CaseDocumentGeneratePtpLetterMutation,
  Types.CaseDocumentGeneratePtpLetterMutationVariables
>

/**
 * __useCaseDocumentGeneratePtpLetterMutation__
 *
 * To run a mutation, you first call `useCaseDocumentGeneratePtpLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseDocumentGeneratePtpLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseDocumentGeneratePtpLetterMutation, { data, loading, error }] = useCaseDocumentGeneratePtpLetterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseDocumentGeneratePtpLetterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseDocumentGeneratePtpLetterMutation,
    Types.CaseDocumentGeneratePtpLetterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseDocumentGeneratePtpLetterMutation,
    Types.CaseDocumentGeneratePtpLetterMutationVariables
  >(CaseDocumentGeneratePtpLetterDocument, options)
}
export type CaseDocumentGeneratePtpLetterMutationHookResult = ReturnType<
  typeof useCaseDocumentGeneratePtpLetterMutation
>
export type CaseDocumentGeneratePtpLetterMutationResult =
  Apollo.MutationResult<Types.CaseDocumentGeneratePtpLetterMutation>
export type CaseDocumentGeneratePtpLetterMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CaseDocumentGeneratePtpLetterMutation,
    Types.CaseDocumentGeneratePtpLetterMutationVariables
  >
export const CaseDocumentGeneratePowerOfAttorneyDocument = gql`
  mutation CaseDocumentGeneratePowerOfAttorney(
    $id: ID!
    $input: CaseDocumentGeneratePowerOfAttorneyInput!
  ) {
    caseDocumentGeneratePowerOfAttorney(id: $id, input: $input) {
      caseDocument {
        file {
          url
        }
        case {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailCaseDocument
              }
            }
            totalCount
          }
        }
      }
    }
  }
  ${CaseDetailCaseDocumentFragmentDoc}
`
export type CaseDocumentGeneratePowerOfAttorneyMutationFn =
  Apollo.MutationFunction<
    Types.CaseDocumentGeneratePowerOfAttorneyMutation,
    Types.CaseDocumentGeneratePowerOfAttorneyMutationVariables
  >

/**
 * __useCaseDocumentGeneratePowerOfAttorneyMutation__
 *
 * To run a mutation, you first call `useCaseDocumentGeneratePowerOfAttorneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseDocumentGeneratePowerOfAttorneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseDocumentGeneratePowerOfAttorneyMutation, { data, loading, error }] = useCaseDocumentGeneratePowerOfAttorneyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseDocumentGeneratePowerOfAttorneyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseDocumentGeneratePowerOfAttorneyMutation,
    Types.CaseDocumentGeneratePowerOfAttorneyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseDocumentGeneratePowerOfAttorneyMutation,
    Types.CaseDocumentGeneratePowerOfAttorneyMutationVariables
  >(CaseDocumentGeneratePowerOfAttorneyDocument, options)
}
export type CaseDocumentGeneratePowerOfAttorneyMutationHookResult = ReturnType<
  typeof useCaseDocumentGeneratePowerOfAttorneyMutation
>
export type CaseDocumentGeneratePowerOfAttorneyMutationResult =
  Apollo.MutationResult<Types.CaseDocumentGeneratePowerOfAttorneyMutation>
export type CaseDocumentGeneratePowerOfAttorneyMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CaseDocumentGeneratePowerOfAttorneyMutation,
    Types.CaseDocumentGeneratePowerOfAttorneyMutationVariables
  >
export const CaseDocumentUpdateDocument = gql`
  mutation CaseDocumentUpdate($id: ID!, $input: CaseDocumentUpdateInput!) {
    caseDocumentUpdate(id: $id, input: $input) {
      caseDocument {
        id
        file {
          fileName
        }
        case {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailDocument
              }
            }
            totalCount
          }
          events(last: 250) {
            edges {
              node {
                ...CaseEvent
              }
            }
          }
        }
      }
    }
  }
  ${CaseDetailDocumentFragmentDoc}
  ${CaseEventFragmentDoc}
`
export type CaseDocumentUpdateMutationFn = Apollo.MutationFunction<
  Types.CaseDocumentUpdateMutation,
  Types.CaseDocumentUpdateMutationVariables
>

/**
 * __useCaseDocumentUpdateMutation__
 *
 * To run a mutation, you first call `useCaseDocumentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseDocumentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseDocumentUpdateMutation, { data, loading, error }] = useCaseDocumentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseDocumentUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseDocumentUpdateMutation,
    Types.CaseDocumentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseDocumentUpdateMutation,
    Types.CaseDocumentUpdateMutationVariables
  >(CaseDocumentUpdateDocument, options)
}
export type CaseDocumentUpdateMutationHookResult = ReturnType<
  typeof useCaseDocumentUpdateMutation
>
export type CaseDocumentUpdateMutationResult =
  Apollo.MutationResult<Types.CaseDocumentUpdateMutation>
export type CaseDocumentUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseDocumentUpdateMutation,
  Types.CaseDocumentUpdateMutationVariables
>
export const CaseEmailAssignDocument = gql`
  mutation CaseEmailAssign($id: ID!, $input: CaseEmailAssignInput!) {
    caseEmailAssign(id: $id, input: $input) {
      case {
        ...CaseDetail
        customer {
          contactEmails {
            ...CustomerEmail
          }
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
  ${CustomerEmailFragmentDoc}
`
export type CaseEmailAssignMutationFn = Apollo.MutationFunction<
  Types.CaseEmailAssignMutation,
  Types.CaseEmailAssignMutationVariables
>

/**
 * __useCaseEmailAssignMutation__
 *
 * To run a mutation, you first call `useCaseEmailAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseEmailAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseEmailAssignMutation, { data, loading, error }] = useCaseEmailAssignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseEmailAssignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseEmailAssignMutation,
    Types.CaseEmailAssignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseEmailAssignMutation,
    Types.CaseEmailAssignMutationVariables
  >(CaseEmailAssignDocument, options)
}
export type CaseEmailAssignMutationHookResult = ReturnType<
  typeof useCaseEmailAssignMutation
>
export type CaseEmailAssignMutationResult =
  Apollo.MutationResult<Types.CaseEmailAssignMutation>
export type CaseEmailAssignMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseEmailAssignMutation,
  Types.CaseEmailAssignMutationVariables
>
export const CaseEmailAssignNewDocument = gql`
  mutation CaseEmailAssignNew($id: ID!, $input: CaseEmailAssignNewInput!) {
    caseEmailAssignNew(id: $id, input: $input) {
      case {
        ...CaseDetail
        customer {
          contactEmails {
            ...CustomerEmail
          }
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
  ${CustomerEmailFragmentDoc}
`
export type CaseEmailAssignNewMutationFn = Apollo.MutationFunction<
  Types.CaseEmailAssignNewMutation,
  Types.CaseEmailAssignNewMutationVariables
>

/**
 * __useCaseEmailAssignNewMutation__
 *
 * To run a mutation, you first call `useCaseEmailAssignNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseEmailAssignNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseEmailAssignNewMutation, { data, loading, error }] = useCaseEmailAssignNewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseEmailAssignNewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseEmailAssignNewMutation,
    Types.CaseEmailAssignNewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseEmailAssignNewMutation,
    Types.CaseEmailAssignNewMutationVariables
  >(CaseEmailAssignNewDocument, options)
}
export type CaseEmailAssignNewMutationHookResult = ReturnType<
  typeof useCaseEmailAssignNewMutation
>
export type CaseEmailAssignNewMutationResult =
  Apollo.MutationResult<Types.CaseEmailAssignNewMutation>
export type CaseEmailAssignNewMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseEmailAssignNewMutation,
  Types.CaseEmailAssignNewMutationVariables
>
export const CaseFeeCreateDocument = gql`
  mutation CaseFeeCreate($caseId: ID!, $input: CaseFeeCreateInput!) {
    caseFeeCreate(caseId: $caseId, input: $input) {
      caseFee {
        case {
          id
          fee {
            ...CaseDetailFee
          }
        }
      }
    }
  }
  ${CaseDetailFeeFragmentDoc}
`
export type CaseFeeCreateMutationFn = Apollo.MutationFunction<
  Types.CaseFeeCreateMutation,
  Types.CaseFeeCreateMutationVariables
>

/**
 * __useCaseFeeCreateMutation__
 *
 * To run a mutation, you first call `useCaseFeeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseFeeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseFeeCreateMutation, { data, loading, error }] = useCaseFeeCreateMutation({
 *   variables: {
 *      caseId: // value for 'caseId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseFeeCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseFeeCreateMutation,
    Types.CaseFeeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseFeeCreateMutation,
    Types.CaseFeeCreateMutationVariables
  >(CaseFeeCreateDocument, options)
}
export type CaseFeeCreateMutationHookResult = ReturnType<
  typeof useCaseFeeCreateMutation
>
export type CaseFeeCreateMutationResult =
  Apollo.MutationResult<Types.CaseFeeCreateMutation>
export type CaseFeeCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseFeeCreateMutation,
  Types.CaseFeeCreateMutationVariables
>
export const CaseFeePaymentAddDocument = gql`
  mutation CaseFeePaymentAdd($id: ID!, $input: CaseFeePaymentCreateInput!) {
    caseFeePaymentCreate(id: $id, input: $input) {
      caseFeePayment {
        fee {
          ...CaseDetailFee
        }
      }
    }
  }
  ${CaseDetailFeeFragmentDoc}
`
export type CaseFeePaymentAddMutationFn = Apollo.MutationFunction<
  Types.CaseFeePaymentAddMutation,
  Types.CaseFeePaymentAddMutationVariables
>

/**
 * __useCaseFeePaymentAddMutation__
 *
 * To run a mutation, you first call `useCaseFeePaymentAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseFeePaymentAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseFeePaymentAddMutation, { data, loading, error }] = useCaseFeePaymentAddMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseFeePaymentAddMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseFeePaymentAddMutation,
    Types.CaseFeePaymentAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseFeePaymentAddMutation,
    Types.CaseFeePaymentAddMutationVariables
  >(CaseFeePaymentAddDocument, options)
}
export type CaseFeePaymentAddMutationHookResult = ReturnType<
  typeof useCaseFeePaymentAddMutation
>
export type CaseFeePaymentAddMutationResult =
  Apollo.MutationResult<Types.CaseFeePaymentAddMutation>
export type CaseFeePaymentAddMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseFeePaymentAddMutation,
  Types.CaseFeePaymentAddMutationVariables
>
export const CaseFeePaymentDeleteDocument = gql`
  mutation CaseFeePaymentDelete($id: ID!) {
    caseFeePaymentDelete(id: $id) {
      caseFeePayment {
        fee {
          ...CaseDetailFee
        }
      }
    }
  }
  ${CaseDetailFeeFragmentDoc}
`
export type CaseFeePaymentDeleteMutationFn = Apollo.MutationFunction<
  Types.CaseFeePaymentDeleteMutation,
  Types.CaseFeePaymentDeleteMutationVariables
>

/**
 * __useCaseFeePaymentDeleteMutation__
 *
 * To run a mutation, you first call `useCaseFeePaymentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseFeePaymentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseFeePaymentDeleteMutation, { data, loading, error }] = useCaseFeePaymentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseFeePaymentDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseFeePaymentDeleteMutation,
    Types.CaseFeePaymentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseFeePaymentDeleteMutation,
    Types.CaseFeePaymentDeleteMutationVariables
  >(CaseFeePaymentDeleteDocument, options)
}
export type CaseFeePaymentDeleteMutationHookResult = ReturnType<
  typeof useCaseFeePaymentDeleteMutation
>
export type CaseFeePaymentDeleteMutationResult =
  Apollo.MutationResult<Types.CaseFeePaymentDeleteMutation>
export type CaseFeePaymentDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseFeePaymentDeleteMutation,
  Types.CaseFeePaymentDeleteMutationVariables
>
export const CaseFeePaymentUpdateDocument = gql`
  mutation CaseFeePaymentUpdate($id: ID!, $input: CaseFeePaymentUpdateInput!) {
    caseFeePaymentUpdate(id: $id, input: $input) {
      caseFeePayment {
        fee {
          ...CaseDetailFee
        }
      }
    }
  }
  ${CaseDetailFeeFragmentDoc}
`
export type CaseFeePaymentUpdateMutationFn = Apollo.MutationFunction<
  Types.CaseFeePaymentUpdateMutation,
  Types.CaseFeePaymentUpdateMutationVariables
>

/**
 * __useCaseFeePaymentUpdateMutation__
 *
 * To run a mutation, you first call `useCaseFeePaymentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseFeePaymentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseFeePaymentUpdateMutation, { data, loading, error }] = useCaseFeePaymentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseFeePaymentUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseFeePaymentUpdateMutation,
    Types.CaseFeePaymentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseFeePaymentUpdateMutation,
    Types.CaseFeePaymentUpdateMutationVariables
  >(CaseFeePaymentUpdateDocument, options)
}
export type CaseFeePaymentUpdateMutationHookResult = ReturnType<
  typeof useCaseFeePaymentUpdateMutation
>
export type CaseFeePaymentUpdateMutationResult =
  Apollo.MutationResult<Types.CaseFeePaymentUpdateMutation>
export type CaseFeePaymentUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseFeePaymentUpdateMutation,
  Types.CaseFeePaymentUpdateMutationVariables
>
export const CaseFeeUpdateDocument = gql`
  mutation CaseFeeUpdate($id: ID!, $input: CaseFeeUpdateInput!) {
    caseFeeUpdate(id: $id, input: $input) {
      caseFee {
        case {
          id
          fee {
            ...CaseDetailFee
          }
        }
      }
    }
  }
  ${CaseDetailFeeFragmentDoc}
`
export type CaseFeeUpdateMutationFn = Apollo.MutationFunction<
  Types.CaseFeeUpdateMutation,
  Types.CaseFeeUpdateMutationVariables
>

/**
 * __useCaseFeeUpdateMutation__
 *
 * To run a mutation, you first call `useCaseFeeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseFeeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseFeeUpdateMutation, { data, loading, error }] = useCaseFeeUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseFeeUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseFeeUpdateMutation,
    Types.CaseFeeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseFeeUpdateMutation,
    Types.CaseFeeUpdateMutationVariables
  >(CaseFeeUpdateDocument, options)
}
export type CaseFeeUpdateMutationHookResult = ReturnType<
  typeof useCaseFeeUpdateMutation
>
export type CaseFeeUpdateMutationResult =
  Apollo.MutationResult<Types.CaseFeeUpdateMutation>
export type CaseFeeUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseFeeUpdateMutation,
  Types.CaseFeeUpdateMutationVariables
>
export const CaseNoteAddDocument = gql`
  mutation CaseNoteAdd($id: ID!, $input: CaseNoteAddInput!) {
    caseNoteAdd(id: $id, input: $input) {
      case {
        ...CaseDetail
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type CaseNoteAddMutationFn = Apollo.MutationFunction<
  Types.CaseNoteAddMutation,
  Types.CaseNoteAddMutationVariables
>

/**
 * __useCaseNoteAddMutation__
 *
 * To run a mutation, you first call `useCaseNoteAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseNoteAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseNoteAddMutation, { data, loading, error }] = useCaseNoteAddMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseNoteAddMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseNoteAddMutation,
    Types.CaseNoteAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseNoteAddMutation,
    Types.CaseNoteAddMutationVariables
  >(CaseNoteAddDocument, options)
}
export type CaseNoteAddMutationHookResult = ReturnType<
  typeof useCaseNoteAddMutation
>
export type CaseNoteAddMutationResult =
  Apollo.MutationResult<Types.CaseNoteAddMutation>
export type CaseNoteAddMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseNoteAddMutation,
  Types.CaseNoteAddMutationVariables
>
export const CasePhoneAssignDocument = gql`
  mutation CasePhoneAssign($id: ID!, $input: CasePhoneAssignInput!) {
    casePhoneAssign(id: $id, input: $input) {
      case {
        ...CaseDetail
        customer {
          contactPhones {
            ...CustomerPhone
          }
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
  ${CustomerPhoneFragmentDoc}
`
export type CasePhoneAssignMutationFn = Apollo.MutationFunction<
  Types.CasePhoneAssignMutation,
  Types.CasePhoneAssignMutationVariables
>

/**
 * __useCasePhoneAssignMutation__
 *
 * To run a mutation, you first call `useCasePhoneAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCasePhoneAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [casePhoneAssignMutation, { data, loading, error }] = useCasePhoneAssignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCasePhoneAssignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CasePhoneAssignMutation,
    Types.CasePhoneAssignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CasePhoneAssignMutation,
    Types.CasePhoneAssignMutationVariables
  >(CasePhoneAssignDocument, options)
}
export type CasePhoneAssignMutationHookResult = ReturnType<
  typeof useCasePhoneAssignMutation
>
export type CasePhoneAssignMutationResult =
  Apollo.MutationResult<Types.CasePhoneAssignMutation>
export type CasePhoneAssignMutationOptions = Apollo.BaseMutationOptions<
  Types.CasePhoneAssignMutation,
  Types.CasePhoneAssignMutationVariables
>
export const CasePhoneAssignNewDocument = gql`
  mutation CasePhoneAssignNew($id: ID!, $input: CasePhoneAssignNewInput!) {
    casePhoneAssignNew(id: $id, input: $input) {
      case {
        ...CaseDetail
        customer {
          contactPhones {
            ...CustomerPhone
          }
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
  ${CustomerPhoneFragmentDoc}
`
export type CasePhoneAssignNewMutationFn = Apollo.MutationFunction<
  Types.CasePhoneAssignNewMutation,
  Types.CasePhoneAssignNewMutationVariables
>

/**
 * __useCasePhoneAssignNewMutation__
 *
 * To run a mutation, you first call `useCasePhoneAssignNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCasePhoneAssignNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [casePhoneAssignNewMutation, { data, loading, error }] = useCasePhoneAssignNewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCasePhoneAssignNewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CasePhoneAssignNewMutation,
    Types.CasePhoneAssignNewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CasePhoneAssignNewMutation,
    Types.CasePhoneAssignNewMutationVariables
  >(CasePhoneAssignNewDocument, options)
}
export type CasePhoneAssignNewMutationHookResult = ReturnType<
  typeof useCasePhoneAssignNewMutation
>
export type CasePhoneAssignNewMutationResult =
  Apollo.MutationResult<Types.CasePhoneAssignNewMutation>
export type CasePhoneAssignNewMutationOptions = Apollo.BaseMutationOptions<
  Types.CasePhoneAssignNewMutation,
  Types.CasePhoneAssignNewMutationVariables
>
export const CaseReopenDocument = gql`
  mutation CaseReopen($id: ID!) {
    caseReopen(id: $id) {
      case {
        ...CaseDetail
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type CaseReopenMutationFn = Apollo.MutationFunction<
  Types.CaseReopenMutation,
  Types.CaseReopenMutationVariables
>

/**
 * __useCaseReopenMutation__
 *
 * To run a mutation, you first call `useCaseReopenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseReopenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseReopenMutation, { data, loading, error }] = useCaseReopenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseReopenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CaseReopenMutation,
    Types.CaseReopenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CaseReopenMutation,
    Types.CaseReopenMutationVariables
  >(CaseReopenDocument, options)
}
export type CaseReopenMutationHookResult = ReturnType<
  typeof useCaseReopenMutation
>
export type CaseReopenMutationResult =
  Apollo.MutationResult<Types.CaseReopenMutation>
export type CaseReopenMutationOptions = Apollo.BaseMutationOptions<
  Types.CaseReopenMutation,
  Types.CaseReopenMutationVariables
>
export const ServiceCreateDocument = gql`
  mutation ServiceCreate($caseId: ID!, $input: ServiceCreateInput!) {
    serviceCreate(caseId: $caseId, input: $input) {
      service {
        title
        case {
          ...CaseDetail
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type ServiceCreateMutationFn = Apollo.MutationFunction<
  Types.ServiceCreateMutation,
  Types.ServiceCreateMutationVariables
>

/**
 * __useServiceCreateMutation__
 *
 * To run a mutation, you first call `useServiceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceCreateMutation, { data, loading, error }] = useServiceCreateMutation({
 *   variables: {
 *      caseId: // value for 'caseId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceCreateMutation,
    Types.ServiceCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceCreateMutation,
    Types.ServiceCreateMutationVariables
  >(ServiceCreateDocument, options)
}
export type ServiceCreateMutationHookResult = ReturnType<
  typeof useServiceCreateMutation
>
export type ServiceCreateMutationResult =
  Apollo.MutationResult<Types.ServiceCreateMutation>
export type ServiceCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceCreateMutation,
  Types.ServiceCreateMutationVariables
>
export const ServiceDeleteDocument = gql`
  mutation ServiceDelete($id: ID!) {
    serviceDelete(id: $id) {
      service {
        title
        case {
          ...CaseDetail
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type ServiceDeleteMutationFn = Apollo.MutationFunction<
  Types.ServiceDeleteMutation,
  Types.ServiceDeleteMutationVariables
>

/**
 * __useServiceDeleteMutation__
 *
 * To run a mutation, you first call `useServiceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceDeleteMutation, { data, loading, error }] = useServiceDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceDeleteMutation,
    Types.ServiceDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceDeleteMutation,
    Types.ServiceDeleteMutationVariables
  >(ServiceDeleteDocument, options)
}
export type ServiceDeleteMutationHookResult = ReturnType<
  typeof useServiceDeleteMutation
>
export type ServiceDeleteMutationResult =
  Apollo.MutationResult<Types.ServiceDeleteMutation>
export type ServiceDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceDeleteMutation,
  Types.ServiceDeleteMutationVariables
>
export const ServiceDocumentCreateDocument = gql`
  mutation ServiceDocumentCreate(
    $serviceId: ID!
    $input: ServiceDocumentCreateInput!
  ) {
    serviceDocumentCreate(id: $serviceId, input: $input) {
      serviceDocument {
        id
        file {
          fileName
        }
        service {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailServiceDocument
              }
            }
            totalCount
          }
          case {
            id
            events(last: 250) {
              edges {
                node {
                  ...CaseEvent
                }
              }
            }
          }
        }
      }
    }
  }
  ${CaseDetailServiceDocumentFragmentDoc}
  ${CaseEventFragmentDoc}
`
export type ServiceDocumentCreateMutationFn = Apollo.MutationFunction<
  Types.ServiceDocumentCreateMutation,
  Types.ServiceDocumentCreateMutationVariables
>

/**
 * __useServiceDocumentCreateMutation__
 *
 * To run a mutation, you first call `useServiceDocumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceDocumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceDocumentCreateMutation, { data, loading, error }] = useServiceDocumentCreateMutation({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceDocumentCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceDocumentCreateMutation,
    Types.ServiceDocumentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceDocumentCreateMutation,
    Types.ServiceDocumentCreateMutationVariables
  >(ServiceDocumentCreateDocument, options)
}
export type ServiceDocumentCreateMutationHookResult = ReturnType<
  typeof useServiceDocumentCreateMutation
>
export type ServiceDocumentCreateMutationResult =
  Apollo.MutationResult<Types.ServiceDocumentCreateMutation>
export type ServiceDocumentCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceDocumentCreateMutation,
  Types.ServiceDocumentCreateMutationVariables
>
export const ServiceDocumentDeleteDocument = gql`
  mutation ServiceDocumentDelete($id: ID!) {
    serviceDocumentDelete(id: $id) {
      serviceDocument {
        id
        file {
          fileName
        }
        service {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailServiceDocument
              }
            }
            totalCount
          }
          case {
            id
            events(last: 250) {
              edges {
                node {
                  ...CaseEvent
                }
              }
            }
          }
        }
      }
    }
  }
  ${CaseDetailServiceDocumentFragmentDoc}
  ${CaseEventFragmentDoc}
`
export type ServiceDocumentDeleteMutationFn = Apollo.MutationFunction<
  Types.ServiceDocumentDeleteMutation,
  Types.ServiceDocumentDeleteMutationVariables
>

/**
 * __useServiceDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useServiceDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceDocumentDeleteMutation, { data, loading, error }] = useServiceDocumentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceDocumentDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceDocumentDeleteMutation,
    Types.ServiceDocumentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceDocumentDeleteMutation,
    Types.ServiceDocumentDeleteMutationVariables
  >(ServiceDocumentDeleteDocument, options)
}
export type ServiceDocumentDeleteMutationHookResult = ReturnType<
  typeof useServiceDocumentDeleteMutation
>
export type ServiceDocumentDeleteMutationResult =
  Apollo.MutationResult<Types.ServiceDocumentDeleteMutation>
export type ServiceDocumentDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceDocumentDeleteMutation,
  Types.ServiceDocumentDeleteMutationVariables
>
export const ServiceDocumentUpdateDocument = gql`
  mutation ServiceDocumentUpdate(
    $id: ID!
    $input: ServiceDocumentUpdateInput!
  ) {
    serviceDocumentUpdate(id: $id, input: $input) {
      serviceDocument {
        id
        file {
          fileName
        }
        service {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailServiceDocument
              }
            }
            totalCount
          }
          case {
            id
            events(last: 250) {
              edges {
                node {
                  ...CaseEvent
                }
              }
            }
          }
        }
      }
    }
  }
  ${CaseDetailServiceDocumentFragmentDoc}
  ${CaseEventFragmentDoc}
`
export type ServiceDocumentUpdateMutationFn = Apollo.MutationFunction<
  Types.ServiceDocumentUpdateMutation,
  Types.ServiceDocumentUpdateMutationVariables
>

/**
 * __useServiceDocumentUpdateMutation__
 *
 * To run a mutation, you first call `useServiceDocumentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceDocumentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceDocumentUpdateMutation, { data, loading, error }] = useServiceDocumentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceDocumentUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceDocumentUpdateMutation,
    Types.ServiceDocumentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceDocumentUpdateMutation,
    Types.ServiceDocumentUpdateMutationVariables
  >(ServiceDocumentUpdateDocument, options)
}
export type ServiceDocumentUpdateMutationHookResult = ReturnType<
  typeof useServiceDocumentUpdateMutation
>
export type ServiceDocumentUpdateMutationResult =
  Apollo.MutationResult<Types.ServiceDocumentUpdateMutation>
export type ServiceDocumentUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceDocumentUpdateMutation,
  Types.ServiceDocumentUpdateMutationVariables
>
export const ServiceSetProviderDocument = gql`
  mutation ServiceSetProvider(
    $id: ID!
    $input: ServiceSetServiceProviderInput!
  ) {
    serviceSetServiceProvider(id: $id, input: $input) {
      service {
        title
        case {
          ...CaseDetail
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type ServiceSetProviderMutationFn = Apollo.MutationFunction<
  Types.ServiceSetProviderMutation,
  Types.ServiceSetProviderMutationVariables
>

/**
 * __useServiceSetProviderMutation__
 *
 * To run a mutation, you first call `useServiceSetProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceSetProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceSetProviderMutation, { data, loading, error }] = useServiceSetProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceSetProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceSetProviderMutation,
    Types.ServiceSetProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceSetProviderMutation,
    Types.ServiceSetProviderMutationVariables
  >(ServiceSetProviderDocument, options)
}
export type ServiceSetProviderMutationHookResult = ReturnType<
  typeof useServiceSetProviderMutation
>
export type ServiceSetProviderMutationResult =
  Apollo.MutationResult<Types.ServiceSetProviderMutation>
export type ServiceSetProviderMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceSetProviderMutation,
  Types.ServiceSetProviderMutationVariables
>
export const ServiceSetProviderContactDocument = gql`
  mutation ServiceSetProviderContact(
    $id: ID!
    $input: ServiceSetServiceProviderContactInput!
  ) {
    serviceSetServiceProviderContact(id: $id, input: $input) {
      service {
        title
        case {
          ...CaseDetail
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type ServiceSetProviderContactMutationFn = Apollo.MutationFunction<
  Types.ServiceSetProviderContactMutation,
  Types.ServiceSetProviderContactMutationVariables
>

/**
 * __useServiceSetProviderContactMutation__
 *
 * To run a mutation, you first call `useServiceSetProviderContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceSetProviderContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceSetProviderContactMutation, { data, loading, error }] = useServiceSetProviderContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceSetProviderContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceSetProviderContactMutation,
    Types.ServiceSetProviderContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceSetProviderContactMutation,
    Types.ServiceSetProviderContactMutationVariables
  >(ServiceSetProviderContactDocument, options)
}
export type ServiceSetProviderContactMutationHookResult = ReturnType<
  typeof useServiceSetProviderContactMutation
>
export type ServiceSetProviderContactMutationResult =
  Apollo.MutationResult<Types.ServiceSetProviderContactMutation>
export type ServiceSetProviderContactMutationOptions =
  Apollo.BaseMutationOptions<
    Types.ServiceSetProviderContactMutation,
    Types.ServiceSetProviderContactMutationVariables
  >
export const ServiceUpdateDocument = gql`
  mutation ServiceUpdate($id: ID!, $input: ServiceUpdateInput!) {
    serviceUpdate(id: $id, input: $input) {
      service {
        title
        case {
          ...CaseDetail
        }
      }
    }
  }
  ${CaseDetailFragmentDoc}
`
export type ServiceUpdateMutationFn = Apollo.MutationFunction<
  Types.ServiceUpdateMutation,
  Types.ServiceUpdateMutationVariables
>

/**
 * __useServiceUpdateMutation__
 *
 * To run a mutation, you first call `useServiceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceUpdateMutation, { data, loading, error }] = useServiceUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceUpdateMutation,
    Types.ServiceUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceUpdateMutation,
    Types.ServiceUpdateMutationVariables
  >(ServiceUpdateDocument, options)
}
export type ServiceUpdateMutationHookResult = ReturnType<
  typeof useServiceUpdateMutation
>
export type ServiceUpdateMutationResult =
  Apollo.MutationResult<Types.ServiceUpdateMutation>
export type ServiceUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceUpdateMutation,
  Types.ServiceUpdateMutationVariables
>
export const CaseDetailDocument = gql`
  query CaseDetail($id: ID!) {
    case(id: $id) {
      ...CaseDetail
    }
    countries {
      code
      name
      isDefault
      areas {
        id
        name
      }
    }
  }
  ${CaseDetailFragmentDoc}
`

/**
 * __useCaseDetailQuery__
 *
 * To run a query within a React component, call `useCaseDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.CaseDetailQuery,
    Types.CaseDetailQueryVariables
  > &
    (
      | { variables: Types.CaseDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CaseDetailQuery,
    Types.CaseDetailQueryVariables
  >(CaseDetailDocument, options)
}
export function useCaseDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CaseDetailQuery,
    Types.CaseDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CaseDetailQuery,
    Types.CaseDetailQueryVariables
  >(CaseDetailDocument, options)
}
export function useCaseDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CaseDetailQuery,
        Types.CaseDetailQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CaseDetailQuery,
    Types.CaseDetailQueryVariables
  >(CaseDetailDocument, options)
}
export type CaseDetailQueryHookResult = ReturnType<typeof useCaseDetailQuery>
export type CaseDetailLazyQueryHookResult = ReturnType<
  typeof useCaseDetailLazyQuery
>
export type CaseDetailSuspenseQueryHookResult = ReturnType<
  typeof useCaseDetailSuspenseQuery
>
export type CaseDetailQueryResult = Apollo.QueryResult<
  Types.CaseDetailQuery,
  Types.CaseDetailQueryVariables
>
export const CaseFilterCloseReasonsDocument = gql`
  query CaseFilterCloseReasons {
    caseCloseReasons(first: 100) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`

/**
 * __useCaseFilterCloseReasonsQuery__
 *
 * To run a query within a React component, call `useCaseFilterCloseReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseFilterCloseReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseFilterCloseReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCaseFilterCloseReasonsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CaseFilterCloseReasonsQuery,
    Types.CaseFilterCloseReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CaseFilterCloseReasonsQuery,
    Types.CaseFilterCloseReasonsQueryVariables
  >(CaseFilterCloseReasonsDocument, options)
}
export function useCaseFilterCloseReasonsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CaseFilterCloseReasonsQuery,
    Types.CaseFilterCloseReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CaseFilterCloseReasonsQuery,
    Types.CaseFilterCloseReasonsQueryVariables
  >(CaseFilterCloseReasonsDocument, options)
}
export function useCaseFilterCloseReasonsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CaseFilterCloseReasonsQuery,
        Types.CaseFilterCloseReasonsQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CaseFilterCloseReasonsQuery,
    Types.CaseFilterCloseReasonsQueryVariables
  >(CaseFilterCloseReasonsDocument, options)
}
export type CaseFilterCloseReasonsQueryHookResult = ReturnType<
  typeof useCaseFilterCloseReasonsQuery
>
export type CaseFilterCloseReasonsLazyQueryHookResult = ReturnType<
  typeof useCaseFilterCloseReasonsLazyQuery
>
export type CaseFilterCloseReasonsSuspenseQueryHookResult = ReturnType<
  typeof useCaseFilterCloseReasonsSuspenseQuery
>
export type CaseFilterCloseReasonsQueryResult = Apollo.QueryResult<
  Types.CaseFilterCloseReasonsQuery,
  Types.CaseFilterCloseReasonsQueryVariables
>
export const CaseListAllDocument = gql`
  query CaseListAll(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: CasesAllFilterInput
    $sort: CasesAllSortInput
  ) {
    cases: casesAll(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
      sort: $sort
    ) {
      edges {
        node {
          ...CaseList
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${CaseListFragmentDoc}
`

/**
 * __useCaseListAllQuery__
 *
 * To run a query within a React component, call `useCaseListAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseListAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseListAllQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCaseListAllQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CaseListAllQuery,
    Types.CaseListAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CaseListAllQuery,
    Types.CaseListAllQueryVariables
  >(CaseListAllDocument, options)
}
export function useCaseListAllLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CaseListAllQuery,
    Types.CaseListAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CaseListAllQuery,
    Types.CaseListAllQueryVariables
  >(CaseListAllDocument, options)
}
export function useCaseListAllSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CaseListAllQuery,
        Types.CaseListAllQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CaseListAllQuery,
    Types.CaseListAllQueryVariables
  >(CaseListAllDocument, options)
}
export type CaseListAllQueryHookResult = ReturnType<typeof useCaseListAllQuery>
export type CaseListAllLazyQueryHookResult = ReturnType<
  typeof useCaseListAllLazyQuery
>
export type CaseListAllSuspenseQueryHookResult = ReturnType<
  typeof useCaseListAllSuspenseQuery
>
export type CaseListAllQueryResult = Apollo.QueryResult<
  Types.CaseListAllQuery,
  Types.CaseListAllQueryVariables
>
export const CaseListAssignedDocument = gql`
  query CaseListAssigned(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: CasesAssignedFilterInput
    $sort: CasesAssignedSortInput
  ) {
    cases: casesAssigned(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
      sort: $sort
    ) {
      edges {
        node {
          ...CaseList
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${CaseListFragmentDoc}
`

/**
 * __useCaseListAssignedQuery__
 *
 * To run a query within a React component, call `useCaseListAssignedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseListAssignedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseListAssignedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCaseListAssignedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CaseListAssignedQuery,
    Types.CaseListAssignedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CaseListAssignedQuery,
    Types.CaseListAssignedQueryVariables
  >(CaseListAssignedDocument, options)
}
export function useCaseListAssignedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CaseListAssignedQuery,
    Types.CaseListAssignedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CaseListAssignedQuery,
    Types.CaseListAssignedQueryVariables
  >(CaseListAssignedDocument, options)
}
export function useCaseListAssignedSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CaseListAssignedQuery,
        Types.CaseListAssignedQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CaseListAssignedQuery,
    Types.CaseListAssignedQueryVariables
  >(CaseListAssignedDocument, options)
}
export type CaseListAssignedQueryHookResult = ReturnType<
  typeof useCaseListAssignedQuery
>
export type CaseListAssignedLazyQueryHookResult = ReturnType<
  typeof useCaseListAssignedLazyQuery
>
export type CaseListAssignedSuspenseQueryHookResult = ReturnType<
  typeof useCaseListAssignedSuspenseQuery
>
export type CaseListAssignedQueryResult = Apollo.QueryResult<
  Types.CaseListAssignedQuery,
  Types.CaseListAssignedQueryVariables
>
export const InitialCaseFilterCustomersDocument = gql`
  query InitialCaseFilterCustomers($customerIds: [ID!]) {
    customers(first: 100, filter: { ids: $customerIds }) {
      edges {
        node {
          id
          displayName
        }
      }
    }
  }
`

/**
 * __useInitialCaseFilterCustomersQuery__
 *
 * To run a query within a React component, call `useInitialCaseFilterCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialCaseFilterCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialCaseFilterCustomersQuery({
 *   variables: {
 *      customerIds: // value for 'customerIds'
 *   },
 * });
 */
export function useInitialCaseFilterCustomersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.InitialCaseFilterCustomersQuery,
    Types.InitialCaseFilterCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.InitialCaseFilterCustomersQuery,
    Types.InitialCaseFilterCustomersQueryVariables
  >(InitialCaseFilterCustomersDocument, options)
}
export function useInitialCaseFilterCustomersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.InitialCaseFilterCustomersQuery,
    Types.InitialCaseFilterCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.InitialCaseFilterCustomersQuery,
    Types.InitialCaseFilterCustomersQueryVariables
  >(InitialCaseFilterCustomersDocument, options)
}
export function useInitialCaseFilterCustomersSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.InitialCaseFilterCustomersQuery,
        Types.InitialCaseFilterCustomersQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.InitialCaseFilterCustomersQuery,
    Types.InitialCaseFilterCustomersQueryVariables
  >(InitialCaseFilterCustomersDocument, options)
}
export type InitialCaseFilterCustomersQueryHookResult = ReturnType<
  typeof useInitialCaseFilterCustomersQuery
>
export type InitialCaseFilterCustomersLazyQueryHookResult = ReturnType<
  typeof useInitialCaseFilterCustomersLazyQuery
>
export type InitialCaseFilterCustomersSuspenseQueryHookResult = ReturnType<
  typeof useInitialCaseFilterCustomersSuspenseQuery
>
export type InitialCaseFilterCustomersQueryResult = Apollo.QueryResult<
  Types.InitialCaseFilterCustomersQuery,
  Types.InitialCaseFilterCustomersQueryVariables
>
export const InitialCaseFilterUsersDocument = gql`
  query InitialCaseFilterUsers($userIds: [ID!]) {
    users(first: 100, filter: { ids: $userIds }) {
      edges {
        node {
          id
          displayName
        }
      }
    }
  }
`

/**
 * __useInitialCaseFilterUsersQuery__
 *
 * To run a query within a React component, call `useInitialCaseFilterUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialCaseFilterUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialCaseFilterUsersQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useInitialCaseFilterUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.InitialCaseFilterUsersQuery,
    Types.InitialCaseFilterUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.InitialCaseFilterUsersQuery,
    Types.InitialCaseFilterUsersQueryVariables
  >(InitialCaseFilterUsersDocument, options)
}
export function useInitialCaseFilterUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.InitialCaseFilterUsersQuery,
    Types.InitialCaseFilterUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.InitialCaseFilterUsersQuery,
    Types.InitialCaseFilterUsersQueryVariables
  >(InitialCaseFilterUsersDocument, options)
}
export function useInitialCaseFilterUsersSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.InitialCaseFilterUsersQuery,
        Types.InitialCaseFilterUsersQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.InitialCaseFilterUsersQuery,
    Types.InitialCaseFilterUsersQueryVariables
  >(InitialCaseFilterUsersDocument, options)
}
export type InitialCaseFilterUsersQueryHookResult = ReturnType<
  typeof useInitialCaseFilterUsersQuery
>
export type InitialCaseFilterUsersLazyQueryHookResult = ReturnType<
  typeof useInitialCaseFilterUsersLazyQuery
>
export type InitialCaseFilterUsersSuspenseQueryHookResult = ReturnType<
  typeof useInitialCaseFilterUsersSuspenseQuery
>
export type InitialCaseFilterUsersQueryResult = Apollo.QueryResult<
  Types.InitialCaseFilterUsersQuery,
  Types.InitialCaseFilterUsersQueryVariables
>
export const NotReferencedDocument = gql`
  query NotReferenced {
    errorCodesAccount
    errorCodesAuth
    errorCodesCommon
    errorCodesCase
    errorCodesService
    errorCodesServiceProvider
    scopes
  }
`

/**
 * __useNotReferencedQuery__
 *
 * To run a query within a React component, call `useNotReferencedQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotReferencedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotReferencedQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotReferencedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.NotReferencedQuery,
    Types.NotReferencedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.NotReferencedQuery,
    Types.NotReferencedQueryVariables
  >(NotReferencedDocument, options)
}
export function useNotReferencedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.NotReferencedQuery,
    Types.NotReferencedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.NotReferencedQuery,
    Types.NotReferencedQueryVariables
  >(NotReferencedDocument, options)
}
export function useNotReferencedSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.NotReferencedQuery,
        Types.NotReferencedQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.NotReferencedQuery,
    Types.NotReferencedQueryVariables
  >(NotReferencedDocument, options)
}
export type NotReferencedQueryHookResult = ReturnType<
  typeof useNotReferencedQuery
>
export type NotReferencedLazyQueryHookResult = ReturnType<
  typeof useNotReferencedLazyQuery
>
export type NotReferencedSuspenseQueryHookResult = ReturnType<
  typeof useNotReferencedSuspenseQuery
>
export type NotReferencedQueryResult = Apollo.QueryResult<
  Types.NotReferencedQuery,
  Types.NotReferencedQueryVariables
>
export const CustomerAddressCreateDocument = gql`
  mutation CustomerAddressCreate(
    $id: ID!
    $input: CustomerAddressCreateInput!
  ) {
    customerAddressCreate(id: $id, input: $input) {
      customerAddress {
        ...CustomerAddress
        customer {
          id
          addresses {
            ...CustomerAddress
            cases {
              totalCount
            }
          }
        }
      }
    }
  }
  ${CustomerAddressFragmentDoc}
`
export type CustomerAddressCreateMutationFn = Apollo.MutationFunction<
  Types.CustomerAddressCreateMutation,
  Types.CustomerAddressCreateMutationVariables
>

/**
 * __useCustomerAddressCreateMutation__
 *
 * To run a mutation, you first call `useCustomerAddressCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddressCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAddressCreateMutation, { data, loading, error }] = useCustomerAddressCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerAddressCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerAddressCreateMutation,
    Types.CustomerAddressCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerAddressCreateMutation,
    Types.CustomerAddressCreateMutationVariables
  >(CustomerAddressCreateDocument, options)
}
export type CustomerAddressCreateMutationHookResult = ReturnType<
  typeof useCustomerAddressCreateMutation
>
export type CustomerAddressCreateMutationResult =
  Apollo.MutationResult<Types.CustomerAddressCreateMutation>
export type CustomerAddressCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerAddressCreateMutation,
  Types.CustomerAddressCreateMutationVariables
>
export const CustomerAddressDeleteDocument = gql`
  mutation CustomerAddressDelete($id: ID!) {
    customerAddressDelete(id: $id) {
      customerAddress {
        ...CustomerAddress
        customer {
          id
          addresses {
            ...CustomerAddress
            cases {
              totalCount
            }
          }
        }
      }
    }
  }
  ${CustomerAddressFragmentDoc}
`
export type CustomerAddressDeleteMutationFn = Apollo.MutationFunction<
  Types.CustomerAddressDeleteMutation,
  Types.CustomerAddressDeleteMutationVariables
>

/**
 * __useCustomerAddressDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerAddressDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddressDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAddressDeleteMutation, { data, loading, error }] = useCustomerAddressDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerAddressDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerAddressDeleteMutation,
    Types.CustomerAddressDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerAddressDeleteMutation,
    Types.CustomerAddressDeleteMutationVariables
  >(CustomerAddressDeleteDocument, options)
}
export type CustomerAddressDeleteMutationHookResult = ReturnType<
  typeof useCustomerAddressDeleteMutation
>
export type CustomerAddressDeleteMutationResult =
  Apollo.MutationResult<Types.CustomerAddressDeleteMutation>
export type CustomerAddressDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerAddressDeleteMutation,
  Types.CustomerAddressDeleteMutationVariables
>
export const CustomerCreateDocument = gql`
  mutation CustomerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        ...CustomerDetail
      }
    }
  }
  ${CustomerDetailFragmentDoc}
`
export type CustomerCreateMutationFn = Apollo.MutationFunction<
  Types.CustomerCreateMutation,
  Types.CustomerCreateMutationVariables
>

/**
 * __useCustomerCreateMutation__
 *
 * To run a mutation, you first call `useCustomerCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerCreateMutation, { data, loading, error }] = useCustomerCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerCreateMutation,
    Types.CustomerCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerCreateMutation,
    Types.CustomerCreateMutationVariables
  >(CustomerCreateDocument, options)
}
export type CustomerCreateMutationHookResult = ReturnType<
  typeof useCustomerCreateMutation
>
export type CustomerCreateMutationResult =
  Apollo.MutationResult<Types.CustomerCreateMutation>
export type CustomerCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerCreateMutation,
  Types.CustomerCreateMutationVariables
>
export const CustomerDeleteDocument = gql`
  mutation CustomerDelete($id: ID!) {
    customerDelete(id: $id) {
      customer {
        ...CustomerDetail
      }
    }
  }
  ${CustomerDetailFragmentDoc}
`
export type CustomerDeleteMutationFn = Apollo.MutationFunction<
  Types.CustomerDeleteMutation,
  Types.CustomerDeleteMutationVariables
>

/**
 * __useCustomerDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerDeleteMutation, { data, loading, error }] = useCustomerDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerDeleteMutation,
    Types.CustomerDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerDeleteMutation,
    Types.CustomerDeleteMutationVariables
  >(CustomerDeleteDocument, options)
}
export type CustomerDeleteMutationHookResult = ReturnType<
  typeof useCustomerDeleteMutation
>
export type CustomerDeleteMutationResult =
  Apollo.MutationResult<Types.CustomerDeleteMutation>
export type CustomerDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerDeleteMutation,
  Types.CustomerDeleteMutationVariables
>
export const CustomerDocumentCreateDocument = gql`
  mutation CustomerDocumentCreate(
    $customerId: ID!
    $input: CustomerDocumentCreateInput!
  ) {
    customerDocumentCreate(id: $customerId, input: $input) {
      customerDocument {
        id
        file {
          fileName
        }
        customer {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailCustomerDocument
              }
            }
            totalCount
          }
        }
      }
    }
  }
  ${CaseDetailCustomerDocumentFragmentDoc}
`
export type CustomerDocumentCreateMutationFn = Apollo.MutationFunction<
  Types.CustomerDocumentCreateMutation,
  Types.CustomerDocumentCreateMutationVariables
>

/**
 * __useCustomerDocumentCreateMutation__
 *
 * To run a mutation, you first call `useCustomerDocumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerDocumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerDocumentCreateMutation, { data, loading, error }] = useCustomerDocumentCreateMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerDocumentCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerDocumentCreateMutation,
    Types.CustomerDocumentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerDocumentCreateMutation,
    Types.CustomerDocumentCreateMutationVariables
  >(CustomerDocumentCreateDocument, options)
}
export type CustomerDocumentCreateMutationHookResult = ReturnType<
  typeof useCustomerDocumentCreateMutation
>
export type CustomerDocumentCreateMutationResult =
  Apollo.MutationResult<Types.CustomerDocumentCreateMutation>
export type CustomerDocumentCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerDocumentCreateMutation,
  Types.CustomerDocumentCreateMutationVariables
>
export const CustomerDocumentDeleteDocument = gql`
  mutation CustomerDocumentDelete($id: ID!) {
    customerDocumentDelete(id: $id) {
      customerDocument {
        id
        file {
          fileName
        }
        customer {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailCustomerDocument
              }
            }
            totalCount
          }
        }
      }
    }
  }
  ${CaseDetailCustomerDocumentFragmentDoc}
`
export type CustomerDocumentDeleteMutationFn = Apollo.MutationFunction<
  Types.CustomerDocumentDeleteMutation,
  Types.CustomerDocumentDeleteMutationVariables
>

/**
 * __useCustomerDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerDocumentDeleteMutation, { data, loading, error }] = useCustomerDocumentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerDocumentDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerDocumentDeleteMutation,
    Types.CustomerDocumentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerDocumentDeleteMutation,
    Types.CustomerDocumentDeleteMutationVariables
  >(CustomerDocumentDeleteDocument, options)
}
export type CustomerDocumentDeleteMutationHookResult = ReturnType<
  typeof useCustomerDocumentDeleteMutation
>
export type CustomerDocumentDeleteMutationResult =
  Apollo.MutationResult<Types.CustomerDocumentDeleteMutation>
export type CustomerDocumentDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerDocumentDeleteMutation,
  Types.CustomerDocumentDeleteMutationVariables
>
export const CustomerDocumentUpdateDocument = gql`
  mutation CustomerDocumentUpdate(
    $id: ID!
    $input: CustomerDocumentUpdateInput!
  ) {
    customerDocumentUpdate(id: $id, input: $input) {
      customerDocument {
        id
        file {
          fileName
        }
        customer {
          id
          documents(first: 100) {
            edges {
              node {
                ...CaseDetailCustomerDocument
              }
            }
            totalCount
          }
        }
      }
    }
  }
  ${CaseDetailCustomerDocumentFragmentDoc}
`
export type CustomerDocumentUpdateMutationFn = Apollo.MutationFunction<
  Types.CustomerDocumentUpdateMutation,
  Types.CustomerDocumentUpdateMutationVariables
>

/**
 * __useCustomerDocumentUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerDocumentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerDocumentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerDocumentUpdateMutation, { data, loading, error }] = useCustomerDocumentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerDocumentUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerDocumentUpdateMutation,
    Types.CustomerDocumentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerDocumentUpdateMutation,
    Types.CustomerDocumentUpdateMutationVariables
  >(CustomerDocumentUpdateDocument, options)
}
export type CustomerDocumentUpdateMutationHookResult = ReturnType<
  typeof useCustomerDocumentUpdateMutation
>
export type CustomerDocumentUpdateMutationResult =
  Apollo.MutationResult<Types.CustomerDocumentUpdateMutation>
export type CustomerDocumentUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerDocumentUpdateMutation,
  Types.CustomerDocumentUpdateMutationVariables
>
export const CustomerEmailCreateDocument = gql`
  mutation CustomerEmailCreate(
    $id: ID!
    $input: CustomerContactEmailCreateInput!
  ) {
    customerContactEmailCreate(id: $id, input: $input) {
      customerContactEmail {
        ...CustomerEmail
        customer {
          id
          contactEmails {
            ...CustomerEmail
            cases {
              totalCount
            }
          }
        }
      }
    }
  }
  ${CustomerEmailFragmentDoc}
`
export type CustomerEmailCreateMutationFn = Apollo.MutationFunction<
  Types.CustomerEmailCreateMutation,
  Types.CustomerEmailCreateMutationVariables
>

/**
 * __useCustomerEmailCreateMutation__
 *
 * To run a mutation, you first call `useCustomerEmailCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerEmailCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerEmailCreateMutation, { data, loading, error }] = useCustomerEmailCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerEmailCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerEmailCreateMutation,
    Types.CustomerEmailCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerEmailCreateMutation,
    Types.CustomerEmailCreateMutationVariables
  >(CustomerEmailCreateDocument, options)
}
export type CustomerEmailCreateMutationHookResult = ReturnType<
  typeof useCustomerEmailCreateMutation
>
export type CustomerEmailCreateMutationResult =
  Apollo.MutationResult<Types.CustomerEmailCreateMutation>
export type CustomerEmailCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerEmailCreateMutation,
  Types.CustomerEmailCreateMutationVariables
>
export const CustomerEmailDeleteDocument = gql`
  mutation CustomerEmailDelete($id: ID!) {
    customerContactEmailDelete(id: $id) {
      customerContactEmail {
        ...CustomerEmail
        customer {
          id
          contactEmails {
            ...CustomerEmail
            cases {
              totalCount
            }
          }
        }
      }
    }
  }
  ${CustomerEmailFragmentDoc}
`
export type CustomerEmailDeleteMutationFn = Apollo.MutationFunction<
  Types.CustomerEmailDeleteMutation,
  Types.CustomerEmailDeleteMutationVariables
>

/**
 * __useCustomerEmailDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerEmailDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerEmailDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerEmailDeleteMutation, { data, loading, error }] = useCustomerEmailDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerEmailDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerEmailDeleteMutation,
    Types.CustomerEmailDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerEmailDeleteMutation,
    Types.CustomerEmailDeleteMutationVariables
  >(CustomerEmailDeleteDocument, options)
}
export type CustomerEmailDeleteMutationHookResult = ReturnType<
  typeof useCustomerEmailDeleteMutation
>
export type CustomerEmailDeleteMutationResult =
  Apollo.MutationResult<Types.CustomerEmailDeleteMutation>
export type CustomerEmailDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerEmailDeleteMutation,
  Types.CustomerEmailDeleteMutationVariables
>
export const CustomerPhoneCreateDocument = gql`
  mutation CustomerPhoneCreate(
    $id: ID!
    $input: CustomerContactPhoneCreateInput!
  ) {
    customerContactPhoneCreate(id: $id, input: $input) {
      customerContactPhone {
        ...CustomerPhone
        customer {
          id
          contactPhones {
            ...CustomerPhone
            cases {
              totalCount
            }
          }
        }
      }
    }
  }
  ${CustomerPhoneFragmentDoc}
`
export type CustomerPhoneCreateMutationFn = Apollo.MutationFunction<
  Types.CustomerPhoneCreateMutation,
  Types.CustomerPhoneCreateMutationVariables
>

/**
 * __useCustomerPhoneCreateMutation__
 *
 * To run a mutation, you first call `useCustomerPhoneCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerPhoneCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerPhoneCreateMutation, { data, loading, error }] = useCustomerPhoneCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerPhoneCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerPhoneCreateMutation,
    Types.CustomerPhoneCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerPhoneCreateMutation,
    Types.CustomerPhoneCreateMutationVariables
  >(CustomerPhoneCreateDocument, options)
}
export type CustomerPhoneCreateMutationHookResult = ReturnType<
  typeof useCustomerPhoneCreateMutation
>
export type CustomerPhoneCreateMutationResult =
  Apollo.MutationResult<Types.CustomerPhoneCreateMutation>
export type CustomerPhoneCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerPhoneCreateMutation,
  Types.CustomerPhoneCreateMutationVariables
>
export const CustomerPhoneDeleteDocument = gql`
  mutation CustomerPhoneDelete($id: ID!) {
    customerContactPhoneDelete(id: $id) {
      customerContactPhone {
        ...CustomerPhone
        customer {
          id
          contactPhones {
            ...CustomerPhone
            cases {
              totalCount
            }
          }
        }
      }
    }
  }
  ${CustomerPhoneFragmentDoc}
`
export type CustomerPhoneDeleteMutationFn = Apollo.MutationFunction<
  Types.CustomerPhoneDeleteMutation,
  Types.CustomerPhoneDeleteMutationVariables
>

/**
 * __useCustomerPhoneDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerPhoneDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerPhoneDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerPhoneDeleteMutation, { data, loading, error }] = useCustomerPhoneDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerPhoneDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerPhoneDeleteMutation,
    Types.CustomerPhoneDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerPhoneDeleteMutation,
    Types.CustomerPhoneDeleteMutationVariables
  >(CustomerPhoneDeleteDocument, options)
}
export type CustomerPhoneDeleteMutationHookResult = ReturnType<
  typeof useCustomerPhoneDeleteMutation
>
export type CustomerPhoneDeleteMutationResult =
  Apollo.MutationResult<Types.CustomerPhoneDeleteMutation>
export type CustomerPhoneDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerPhoneDeleteMutation,
  Types.CustomerPhoneDeleteMutationVariables
>
export const CustomerSetDefaultAddressDocument = gql`
  mutation CustomerSetDefaultAddress($id: ID!) {
    customerAddressSetDefault(id: $id) {
      customerAddress {
        ...CustomerAddress
        customer {
          ...CustomerDetail
        }
      }
    }
  }
  ${CustomerAddressFragmentDoc}
  ${CustomerDetailFragmentDoc}
`
export type CustomerSetDefaultAddressMutationFn = Apollo.MutationFunction<
  Types.CustomerSetDefaultAddressMutation,
  Types.CustomerSetDefaultAddressMutationVariables
>

/**
 * __useCustomerSetDefaultAddressMutation__
 *
 * To run a mutation, you first call `useCustomerSetDefaultAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerSetDefaultAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerSetDefaultAddressMutation, { data, loading, error }] = useCustomerSetDefaultAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerSetDefaultAddressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerSetDefaultAddressMutation,
    Types.CustomerSetDefaultAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerSetDefaultAddressMutation,
    Types.CustomerSetDefaultAddressMutationVariables
  >(CustomerSetDefaultAddressDocument, options)
}
export type CustomerSetDefaultAddressMutationHookResult = ReturnType<
  typeof useCustomerSetDefaultAddressMutation
>
export type CustomerSetDefaultAddressMutationResult =
  Apollo.MutationResult<Types.CustomerSetDefaultAddressMutation>
export type CustomerSetDefaultAddressMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CustomerSetDefaultAddressMutation,
    Types.CustomerSetDefaultAddressMutationVariables
  >
export const CustomerSetDefaultEmailDocument = gql`
  mutation CustomerSetDefaultEmail($id: ID!) {
    customerEmailSetDefault(id: $id) {
      customerContactEmail {
        ...CustomerEmail
        customer {
          ...CustomerDetail
        }
      }
    }
  }
  ${CustomerEmailFragmentDoc}
  ${CustomerDetailFragmentDoc}
`
export type CustomerSetDefaultEmailMutationFn = Apollo.MutationFunction<
  Types.CustomerSetDefaultEmailMutation,
  Types.CustomerSetDefaultEmailMutationVariables
>

/**
 * __useCustomerSetDefaultEmailMutation__
 *
 * To run a mutation, you first call `useCustomerSetDefaultEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerSetDefaultEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerSetDefaultEmailMutation, { data, loading, error }] = useCustomerSetDefaultEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerSetDefaultEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerSetDefaultEmailMutation,
    Types.CustomerSetDefaultEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerSetDefaultEmailMutation,
    Types.CustomerSetDefaultEmailMutationVariables
  >(CustomerSetDefaultEmailDocument, options)
}
export type CustomerSetDefaultEmailMutationHookResult = ReturnType<
  typeof useCustomerSetDefaultEmailMutation
>
export type CustomerSetDefaultEmailMutationResult =
  Apollo.MutationResult<Types.CustomerSetDefaultEmailMutation>
export type CustomerSetDefaultEmailMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerSetDefaultEmailMutation,
  Types.CustomerSetDefaultEmailMutationVariables
>
export const CustomerSetDefaultPhoneDocument = gql`
  mutation CustomerSetDefaultPhone($id: ID!) {
    customerPhoneSetDefault(id: $id) {
      customerContactPhone {
        ...CustomerPhone
        customer {
          ...CustomerDetail
        }
      }
    }
  }
  ${CustomerPhoneFragmentDoc}
  ${CustomerDetailFragmentDoc}
`
export type CustomerSetDefaultPhoneMutationFn = Apollo.MutationFunction<
  Types.CustomerSetDefaultPhoneMutation,
  Types.CustomerSetDefaultPhoneMutationVariables
>

/**
 * __useCustomerSetDefaultPhoneMutation__
 *
 * To run a mutation, you first call `useCustomerSetDefaultPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerSetDefaultPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerSetDefaultPhoneMutation, { data, loading, error }] = useCustomerSetDefaultPhoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerSetDefaultPhoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerSetDefaultPhoneMutation,
    Types.CustomerSetDefaultPhoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerSetDefaultPhoneMutation,
    Types.CustomerSetDefaultPhoneMutationVariables
  >(CustomerSetDefaultPhoneDocument, options)
}
export type CustomerSetDefaultPhoneMutationHookResult = ReturnType<
  typeof useCustomerSetDefaultPhoneMutation
>
export type CustomerSetDefaultPhoneMutationResult =
  Apollo.MutationResult<Types.CustomerSetDefaultPhoneMutation>
export type CustomerSetDefaultPhoneMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerSetDefaultPhoneMutation,
  Types.CustomerSetDefaultPhoneMutationVariables
>
export const CustomerUpdateDocument = gql`
  mutation CustomerUpdate($id: ID!, $input: CustomerUpdateInput!) {
    customerUpdate(id: $id, input: $input) {
      customer {
        ...CustomerDetail
      }
    }
  }
  ${CustomerDetailFragmentDoc}
`
export type CustomerUpdateMutationFn = Apollo.MutationFunction<
  Types.CustomerUpdateMutation,
  Types.CustomerUpdateMutationVariables
>

/**
 * __useCustomerUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerUpdateMutation, { data, loading, error }] = useCustomerUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CustomerUpdateMutation,
    Types.CustomerUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.CustomerUpdateMutation,
    Types.CustomerUpdateMutationVariables
  >(CustomerUpdateDocument, options)
}
export type CustomerUpdateMutationHookResult = ReturnType<
  typeof useCustomerUpdateMutation
>
export type CustomerUpdateMutationResult =
  Apollo.MutationResult<Types.CustomerUpdateMutation>
export type CustomerUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerUpdateMutation,
  Types.CustomerUpdateMutationVariables
>
export const CustomerDetailDocument = gql`
  query CustomerDetail(
    $id: ID!
    $casesFirst: Int
    $casesAfter: String
    $casesLast: Int
    $casesBefore: String
    $servicesFirst: Int
    $servicesAfter: String
    $servicesLast: Int
    $servicesBefore: String
  ) {
    customer(id: $id) {
      ...CustomerDetail
      cases(
        before: $casesBefore
        after: $casesAfter
        first: $casesFirst
        last: $casesLast
      ) {
        edges {
          node {
            ...CustomerDetailCase
          }
        }
        pageInfo {
          ...PageInfo
        }
        totalCount
      }
      services(
        before: $servicesBefore
        after: $servicesAfter
        first: $servicesFirst
        last: $servicesLast
      ) {
        edges {
          node {
            ...CustomerDetailService
          }
        }
        pageInfo {
          ...PageInfo
        }
        totalCount
      }
    }
    countries {
      code
      name
      isDefault
      areas {
        id
        name
      }
    }
  }
  ${CustomerDetailFragmentDoc}
  ${CustomerDetailCaseFragmentDoc}
  ${PageInfoFragmentDoc}
  ${CustomerDetailServiceFragmentDoc}
`

/**
 * __useCustomerDetailQuery__
 *
 * To run a query within a React component, call `useCustomerDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      casesFirst: // value for 'casesFirst'
 *      casesAfter: // value for 'casesAfter'
 *      casesLast: // value for 'casesLast'
 *      casesBefore: // value for 'casesBefore'
 *      servicesFirst: // value for 'servicesFirst'
 *      servicesAfter: // value for 'servicesAfter'
 *      servicesLast: // value for 'servicesLast'
 *      servicesBefore: // value for 'servicesBefore'
 *   },
 * });
 */
export function useCustomerDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.CustomerDetailQuery,
    Types.CustomerDetailQueryVariables
  > &
    (
      | { variables: Types.CustomerDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CustomerDetailQuery,
    Types.CustomerDetailQueryVariables
  >(CustomerDetailDocument, options)
}
export function useCustomerDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CustomerDetailQuery,
    Types.CustomerDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CustomerDetailQuery,
    Types.CustomerDetailQueryVariables
  >(CustomerDetailDocument, options)
}
export function useCustomerDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CustomerDetailQuery,
        Types.CustomerDetailQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CustomerDetailQuery,
    Types.CustomerDetailQueryVariables
  >(CustomerDetailDocument, options)
}
export type CustomerDetailQueryHookResult = ReturnType<
  typeof useCustomerDetailQuery
>
export type CustomerDetailLazyQueryHookResult = ReturnType<
  typeof useCustomerDetailLazyQuery
>
export type CustomerDetailSuspenseQueryHookResult = ReturnType<
  typeof useCustomerDetailSuspenseQuery
>
export type CustomerDetailQueryResult = Apollo.QueryResult<
  Types.CustomerDetailQuery,
  Types.CustomerDetailQueryVariables
>
export const CustomerListDocument = gql`
  query CustomerList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: CustomersFilterInput
    $sort: CustomersSortInput
  ) {
    customers(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
      sort: $sort
    ) {
      edges {
        node {
          ...CustomerList
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${CustomerListFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useCustomerListQuery__
 *
 * To run a query within a React component, call `useCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCustomerListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CustomerListQuery,
    Types.CustomerListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.CustomerListQuery,
    Types.CustomerListQueryVariables
  >(CustomerListDocument, options)
}
export function useCustomerListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CustomerListQuery,
    Types.CustomerListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.CustomerListQuery,
    Types.CustomerListQueryVariables
  >(CustomerListDocument, options)
}
export function useCustomerListSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.CustomerListQuery,
        Types.CustomerListQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.CustomerListQuery,
    Types.CustomerListQueryVariables
  >(CustomerListDocument, options)
}
export type CustomerListQueryHookResult = ReturnType<
  typeof useCustomerListQuery
>
export type CustomerListLazyQueryHookResult = ReturnType<
  typeof useCustomerListLazyQuery
>
export type CustomerListSuspenseQueryHookResult = ReturnType<
  typeof useCustomerListSuspenseQuery
>
export type CustomerListQueryResult = Apollo.QueryResult<
  Types.CustomerListQuery,
  Types.CustomerListQueryVariables
>
export const HomeDocument = gql`
  query Home(
    $PERMISSION_MANAGE_CASES: Boolean!
    $PERMISSION_MANAGE_USERS: Boolean!
  ) {
    pendingCases: casesAll(filter: { status: [PENDING] })
      @include(if: $PERMISSION_MANAGE_CASES) {
      totalCount
    }
    openCases: casesAll(filter: { status: [OPEN] })
      @include(if: $PERMISSION_MANAGE_CASES) {
      totalCount
    }
    assignedCases: casesAssigned(filter: { status: [OPEN] }) {
      totalCount
    }
    caseEvents(first: 15, sort: { field: DATE, direction: DESC }) {
      edges {
        node {
          ...CaseEvent
          case {
            id
            title
          }
        }
      }
    }
    mostActiveUsers: users(
      first: 10
      sort: { field: ASSIGNED_CASES_COUNT, direction: DESC }
    ) @include(if: $PERMISSION_MANAGE_USERS) {
      edges {
        node {
          id
          displayName
          total: assignedToCases {
            totalCount
          }
          active: assignedToCases(filter: { status: [OPEN] }) {
            totalCount
          }
          avatar(size: 120) {
            url
          }
        }
      }
    }
  }
  ${CaseEventFragmentDoc}
`

/**
 * __useHomeQuery__
 *
 * To run a query within a React component, call `useHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeQuery({
 *   variables: {
 *      PERMISSION_MANAGE_CASES: // value for 'PERMISSION_MANAGE_CASES'
 *      PERMISSION_MANAGE_USERS: // value for 'PERMISSION_MANAGE_USERS'
 *   },
 * });
 */
export function useHomeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.HomeQuery,
    Types.HomeQueryVariables
  > &
    (
      | { variables: Types.HomeQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<Types.HomeQuery, Types.HomeQueryVariables>(
    HomeDocument,
    options,
  )
}
export function useHomeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HomeQuery,
    Types.HomeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.HomeQuery,
    Types.HomeQueryVariables
  >(HomeDocument, options)
}
export function useHomeSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.HomeQuery,
        Types.HomeQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.HomeQuery,
    Types.HomeQueryVariables
  >(HomeDocument, options)
}
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>
export type HomeSuspenseQueryHookResult = ReturnType<
  typeof useHomeSuspenseQuery
>
export type HomeQueryResult = Apollo.QueryResult<
  Types.HomeQuery,
  Types.HomeQueryVariables
>
export const ReportAssignedLeadsDocument = gql`
  query ReportAssignedLeads(
    $since: DateTime!
    $until: DateTime!
    $period: Period!
  ) {
    reportAssignedLeads(since: $since, until: $until, reportPeriod: $period) {
      interval {
        since
        until
      }
      data {
        user {
          displayName
          roleDescription
        }
        intervalCounts {
          interval {
            since
            until
          }
          countManuallyAssigned
          countAutoAssigned
        }
      }
    }
  }
`

/**
 * __useReportAssignedLeadsQuery__
 *
 * To run a query within a React component, call `useReportAssignedLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportAssignedLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportAssignedLeadsQuery({
 *   variables: {
 *      since: // value for 'since'
 *      until: // value for 'until'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useReportAssignedLeadsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.ReportAssignedLeadsQuery,
    Types.ReportAssignedLeadsQueryVariables
  > &
    (
      | { variables: Types.ReportAssignedLeadsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.ReportAssignedLeadsQuery,
    Types.ReportAssignedLeadsQueryVariables
  >(ReportAssignedLeadsDocument, options)
}
export function useReportAssignedLeadsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ReportAssignedLeadsQuery,
    Types.ReportAssignedLeadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.ReportAssignedLeadsQuery,
    Types.ReportAssignedLeadsQueryVariables
  >(ReportAssignedLeadsDocument, options)
}
export function useReportAssignedLeadsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.ReportAssignedLeadsQuery,
        Types.ReportAssignedLeadsQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.ReportAssignedLeadsQuery,
    Types.ReportAssignedLeadsQueryVariables
  >(ReportAssignedLeadsDocument, options)
}
export type ReportAssignedLeadsQueryHookResult = ReturnType<
  typeof useReportAssignedLeadsQuery
>
export type ReportAssignedLeadsLazyQueryHookResult = ReturnType<
  typeof useReportAssignedLeadsLazyQuery
>
export type ReportAssignedLeadsSuspenseQueryHookResult = ReturnType<
  typeof useReportAssignedLeadsSuspenseQuery
>
export type ReportAssignedLeadsQueryResult = Apollo.QueryResult<
  Types.ReportAssignedLeadsQuery,
  Types.ReportAssignedLeadsQueryVariables
>
export const ReportNewLeadsDocument = gql`
  query ReportNewLeads($since: DateTime!, $until: DateTime!, $period: Period!) {
    reportNewLeads(since: $since, until: $until, reportPeriod: $period) {
      interval {
        since
        until
      }
      leadsByAuthor {
        createdBy {
          ...Author
        }
        intervalCounts {
          interval {
            since
            until
          }
          count
        }
        totalCount
      }
      leadsTotal {
        interval {
          since
          until
        }
        count
      }
    }
  }
  ${AuthorFragmentDoc}
`

/**
 * __useReportNewLeadsQuery__
 *
 * To run a query within a React component, call `useReportNewLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportNewLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportNewLeadsQuery({
 *   variables: {
 *      since: // value for 'since'
 *      until: // value for 'until'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useReportNewLeadsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.ReportNewLeadsQuery,
    Types.ReportNewLeadsQueryVariables
  > &
    (
      | { variables: Types.ReportNewLeadsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.ReportNewLeadsQuery,
    Types.ReportNewLeadsQueryVariables
  >(ReportNewLeadsDocument, options)
}
export function useReportNewLeadsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ReportNewLeadsQuery,
    Types.ReportNewLeadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.ReportNewLeadsQuery,
    Types.ReportNewLeadsQueryVariables
  >(ReportNewLeadsDocument, options)
}
export function useReportNewLeadsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.ReportNewLeadsQuery,
        Types.ReportNewLeadsQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.ReportNewLeadsQuery,
    Types.ReportNewLeadsQueryVariables
  >(ReportNewLeadsDocument, options)
}
export type ReportNewLeadsQueryHookResult = ReturnType<
  typeof useReportNewLeadsQuery
>
export type ReportNewLeadsLazyQueryHookResult = ReturnType<
  typeof useReportNewLeadsLazyQuery
>
export type ReportNewLeadsSuspenseQueryHookResult = ReturnType<
  typeof useReportNewLeadsSuspenseQuery
>
export type ReportNewLeadsQueryResult = Apollo.QueryResult<
  Types.ReportNewLeadsQuery,
  Types.ReportNewLeadsQueryVariables
>
export const SearchCustomersDocument = gql`
  query SearchCustomers($after: String, $first: Int!, $query: String!) {
    search: customers(
      after: $after
      first: $first
      filter: { search: $query }
      sort: { field: NAME, direction: ASC }
    ) {
      edges {
        node {
          id
          defaultEmail {
            emailAddress
          }
          defaultPhone {
            phoneNumber
          }
          displayName
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useSearchCustomersQuery__
 *
 * To run a query within a React component, call `useSearchCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCustomersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchCustomersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.SearchCustomersQuery,
    Types.SearchCustomersQueryVariables
  > &
    (
      | { variables: Types.SearchCustomersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.SearchCustomersQuery,
    Types.SearchCustomersQueryVariables
  >(SearchCustomersDocument, options)
}
export function useSearchCustomersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SearchCustomersQuery,
    Types.SearchCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.SearchCustomersQuery,
    Types.SearchCustomersQueryVariables
  >(SearchCustomersDocument, options)
}
export function useSearchCustomersSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.SearchCustomersQuery,
        Types.SearchCustomersQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.SearchCustomersQuery,
    Types.SearchCustomersQueryVariables
  >(SearchCustomersDocument, options)
}
export type SearchCustomersQueryHookResult = ReturnType<
  typeof useSearchCustomersQuery
>
export type SearchCustomersLazyQueryHookResult = ReturnType<
  typeof useSearchCustomersLazyQuery
>
export type SearchCustomersSuspenseQueryHookResult = ReturnType<
  typeof useSearchCustomersSuspenseQuery
>
export type SearchCustomersQueryResult = Apollo.QueryResult<
  Types.SearchCustomersQuery,
  Types.SearchCustomersQueryVariables
>
export const SearchNegotiatorsDocument = gql`
  query SearchNegotiators($after: String, $first: Int!, $query: String!) {
    search: users(
      after: $after
      first: $first
      filter: { search: $query, role: [NEGOTIATOR] }
      sort: { field: LAST_NAME, direction: ASC }
    ) {
      edges {
        node {
          ...UserInfo
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${UserInfoFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useSearchNegotiatorsQuery__
 *
 * To run a query within a React component, call `useSearchNegotiatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNegotiatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNegotiatorsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchNegotiatorsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.SearchNegotiatorsQuery,
    Types.SearchNegotiatorsQueryVariables
  > &
    (
      | { variables: Types.SearchNegotiatorsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.SearchNegotiatorsQuery,
    Types.SearchNegotiatorsQueryVariables
  >(SearchNegotiatorsDocument, options)
}
export function useSearchNegotiatorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SearchNegotiatorsQuery,
    Types.SearchNegotiatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.SearchNegotiatorsQuery,
    Types.SearchNegotiatorsQueryVariables
  >(SearchNegotiatorsDocument, options)
}
export function useSearchNegotiatorsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.SearchNegotiatorsQuery,
        Types.SearchNegotiatorsQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.SearchNegotiatorsQuery,
    Types.SearchNegotiatorsQueryVariables
  >(SearchNegotiatorsDocument, options)
}
export type SearchNegotiatorsQueryHookResult = ReturnType<
  typeof useSearchNegotiatorsQuery
>
export type SearchNegotiatorsLazyQueryHookResult = ReturnType<
  typeof useSearchNegotiatorsLazyQuery
>
export type SearchNegotiatorsSuspenseQueryHookResult = ReturnType<
  typeof useSearchNegotiatorsSuspenseQuery
>
export type SearchNegotiatorsQueryResult = Apollo.QueryResult<
  Types.SearchNegotiatorsQuery,
  Types.SearchNegotiatorsQueryVariables
>
export const SearchOperatorsDocument = gql`
  query SearchOperators($after: String, $first: Int!, $query: String!) {
    search: users(
      after: $after
      first: $first
      filter: { search: $query, role: [OPERATOR] }
      sort: { field: LAST_NAME, direction: ASC }
    ) {
      edges {
        node {
          ...UserInfo
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${UserInfoFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useSearchOperatorsQuery__
 *
 * To run a query within a React component, call `useSearchOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOperatorsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchOperatorsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.SearchOperatorsQuery,
    Types.SearchOperatorsQueryVariables
  > &
    (
      | { variables: Types.SearchOperatorsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.SearchOperatorsQuery,
    Types.SearchOperatorsQueryVariables
  >(SearchOperatorsDocument, options)
}
export function useSearchOperatorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SearchOperatorsQuery,
    Types.SearchOperatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.SearchOperatorsQuery,
    Types.SearchOperatorsQueryVariables
  >(SearchOperatorsDocument, options)
}
export function useSearchOperatorsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.SearchOperatorsQuery,
        Types.SearchOperatorsQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.SearchOperatorsQuery,
    Types.SearchOperatorsQueryVariables
  >(SearchOperatorsDocument, options)
}
export type SearchOperatorsQueryHookResult = ReturnType<
  typeof useSearchOperatorsQuery
>
export type SearchOperatorsLazyQueryHookResult = ReturnType<
  typeof useSearchOperatorsLazyQuery
>
export type SearchOperatorsSuspenseQueryHookResult = ReturnType<
  typeof useSearchOperatorsSuspenseQuery
>
export type SearchOperatorsQueryResult = Apollo.QueryResult<
  Types.SearchOperatorsQuery,
  Types.SearchOperatorsQueryVariables
>
export const SearchServiceProviderContactsDocument = gql`
  query SearchServiceProviderContacts(
    $serviceProviderId: ID!
    $after: String
    $first: Int!
    $query: String!
  ) {
    search: serviceProviderContacts(
      after: $after
      first: $first
      filter: { serviceProvider: $serviceProviderId, search: $query }
    ) {
      edges {
        node {
          id
          displayName
          firstName
          lastName
          middleName
          role
          phoneNumber
          phoneNumberNote
          emailAddress
          emailAddressNote
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useSearchServiceProviderContactsQuery__
 *
 * To run a query within a React component, call `useSearchServiceProviderContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchServiceProviderContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchServiceProviderContactsQuery({
 *   variables: {
 *      serviceProviderId: // value for 'serviceProviderId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchServiceProviderContactsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.SearchServiceProviderContactsQuery,
    Types.SearchServiceProviderContactsQueryVariables
  > &
    (
      | {
          variables: Types.SearchServiceProviderContactsQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.SearchServiceProviderContactsQuery,
    Types.SearchServiceProviderContactsQueryVariables
  >(SearchServiceProviderContactsDocument, options)
}
export function useSearchServiceProviderContactsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SearchServiceProviderContactsQuery,
    Types.SearchServiceProviderContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.SearchServiceProviderContactsQuery,
    Types.SearchServiceProviderContactsQueryVariables
  >(SearchServiceProviderContactsDocument, options)
}
export function useSearchServiceProviderContactsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.SearchServiceProviderContactsQuery,
        Types.SearchServiceProviderContactsQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.SearchServiceProviderContactsQuery,
    Types.SearchServiceProviderContactsQueryVariables
  >(SearchServiceProviderContactsDocument, options)
}
export type SearchServiceProviderContactsQueryHookResult = ReturnType<
  typeof useSearchServiceProviderContactsQuery
>
export type SearchServiceProviderContactsLazyQueryHookResult = ReturnType<
  typeof useSearchServiceProviderContactsLazyQuery
>
export type SearchServiceProviderContactsSuspenseQueryHookResult = ReturnType<
  typeof useSearchServiceProviderContactsSuspenseQuery
>
export type SearchServiceProviderContactsQueryResult = Apollo.QueryResult<
  Types.SearchServiceProviderContactsQuery,
  Types.SearchServiceProviderContactsQueryVariables
>
export const SearchServiceTypeServiceProviderDocument = gql`
  query SearchServiceTypeServiceProvider(
    $serviceTypeId: ID!
    $after: String
    $first: Int!
    $query: String!
  ) {
    search: serviceTypeAvailableServiceProviders(
      serviceTypeId: $serviceTypeId
      after: $after
      first: $first
      filter: { search: $query }
    ) {
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useSearchServiceTypeServiceProviderQuery__
 *
 * To run a query within a React component, call `useSearchServiceTypeServiceProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchServiceTypeServiceProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchServiceTypeServiceProviderQuery({
 *   variables: {
 *      serviceTypeId: // value for 'serviceTypeId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchServiceTypeServiceProviderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.SearchServiceTypeServiceProviderQuery,
    Types.SearchServiceTypeServiceProviderQueryVariables
  > &
    (
      | {
          variables: Types.SearchServiceTypeServiceProviderQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.SearchServiceTypeServiceProviderQuery,
    Types.SearchServiceTypeServiceProviderQueryVariables
  >(SearchServiceTypeServiceProviderDocument, options)
}
export function useSearchServiceTypeServiceProviderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SearchServiceTypeServiceProviderQuery,
    Types.SearchServiceTypeServiceProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.SearchServiceTypeServiceProviderQuery,
    Types.SearchServiceTypeServiceProviderQueryVariables
  >(SearchServiceTypeServiceProviderDocument, options)
}
export function useSearchServiceTypeServiceProviderSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.SearchServiceTypeServiceProviderQuery,
        Types.SearchServiceTypeServiceProviderQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.SearchServiceTypeServiceProviderQuery,
    Types.SearchServiceTypeServiceProviderQueryVariables
  >(SearchServiceTypeServiceProviderDocument, options)
}
export type SearchServiceTypeServiceProviderQueryHookResult = ReturnType<
  typeof useSearchServiceTypeServiceProviderQuery
>
export type SearchServiceTypeServiceProviderLazyQueryHookResult = ReturnType<
  typeof useSearchServiceTypeServiceProviderLazyQuery
>
export type SearchServiceTypeServiceProviderSuspenseQueryHookResult =
  ReturnType<typeof useSearchServiceTypeServiceProviderSuspenseQuery>
export type SearchServiceTypeServiceProviderQueryResult = Apollo.QueryResult<
  Types.SearchServiceTypeServiceProviderQuery,
  Types.SearchServiceTypeServiceProviderQueryVariables
>
export const SearchUsersDocument = gql`
  query SearchUsers($after: String, $first: Int!, $query: String!) {
    search: users(
      after: $after
      first: $first
      filter: { search: $query }
      sort: { field: LAST_NAME, direction: ASC }
    ) {
      edges {
        node {
          ...UserInfo
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${UserInfoFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables
  > &
    (
      | { variables: Types.SearchUsersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables
  >(SearchUsersDocument, options)
}
export function useSearchUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables
  >(SearchUsersDocument, options)
}
export function useSearchUsersSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.SearchUsersQuery,
        Types.SearchUsersQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables
  >(SearchUsersDocument, options)
}
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>
export type SearchUsersLazyQueryHookResult = ReturnType<
  typeof useSearchUsersLazyQuery
>
export type SearchUsersSuspenseQueryHookResult = ReturnType<
  typeof useSearchUsersSuspenseQuery
>
export type SearchUsersQueryResult = Apollo.QueryResult<
  Types.SearchUsersQuery,
  Types.SearchUsersQueryVariables
>
export const ServiceProviderContactCreateDocument = gql`
  mutation ServiceProviderContactCreate(
    $id: ID!
    $input: ServiceProviderContactCreateInput!
  ) {
    serviceProviderContactCreate(id: $id, input: $input) {
      serviceProviderContact {
        ...ServiceProviderDetailContact
        serviceProvider {
          ...ServiceProviderDetail
        }
      }
    }
  }
  ${ServiceProviderDetailContactFragmentDoc}
  ${ServiceProviderDetailFragmentDoc}
`
export type ServiceProviderContactCreateMutationFn = Apollo.MutationFunction<
  Types.ServiceProviderContactCreateMutation,
  Types.ServiceProviderContactCreateMutationVariables
>

/**
 * __useServiceProviderContactCreateMutation__
 *
 * To run a mutation, you first call `useServiceProviderContactCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceProviderContactCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceProviderContactCreateMutation, { data, loading, error }] = useServiceProviderContactCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceProviderContactCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceProviderContactCreateMutation,
    Types.ServiceProviderContactCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceProviderContactCreateMutation,
    Types.ServiceProviderContactCreateMutationVariables
  >(ServiceProviderContactCreateDocument, options)
}
export type ServiceProviderContactCreateMutationHookResult = ReturnType<
  typeof useServiceProviderContactCreateMutation
>
export type ServiceProviderContactCreateMutationResult =
  Apollo.MutationResult<Types.ServiceProviderContactCreateMutation>
export type ServiceProviderContactCreateMutationOptions =
  Apollo.BaseMutationOptions<
    Types.ServiceProviderContactCreateMutation,
    Types.ServiceProviderContactCreateMutationVariables
  >
export const ServiceProviderCreateDocument = gql`
  mutation ServiceProviderCreate($input: ServiceProviderCreateInput!) {
    serviceProviderCreate(input: $input) {
      serviceProvider {
        ...ServiceProviderList
      }
    }
  }
  ${ServiceProviderListFragmentDoc}
`
export type ServiceProviderCreateMutationFn = Apollo.MutationFunction<
  Types.ServiceProviderCreateMutation,
  Types.ServiceProviderCreateMutationVariables
>

/**
 * __useServiceProviderCreateMutation__
 *
 * To run a mutation, you first call `useServiceProviderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceProviderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceProviderCreateMutation, { data, loading, error }] = useServiceProviderCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceProviderCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceProviderCreateMutation,
    Types.ServiceProviderCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceProviderCreateMutation,
    Types.ServiceProviderCreateMutationVariables
  >(ServiceProviderCreateDocument, options)
}
export type ServiceProviderCreateMutationHookResult = ReturnType<
  typeof useServiceProviderCreateMutation
>
export type ServiceProviderCreateMutationResult =
  Apollo.MutationResult<Types.ServiceProviderCreateMutation>
export type ServiceProviderCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceProviderCreateMutation,
  Types.ServiceProviderCreateMutationVariables
>
export const ServiceProviderDeleteDocument = gql`
  mutation ServiceProviderDelete($id: ID!) {
    serviceProviderDelete(id: $id) {
      serviceProvider {
        ...ServiceProviderDetail
      }
    }
  }
  ${ServiceProviderDetailFragmentDoc}
`
export type ServiceProviderDeleteMutationFn = Apollo.MutationFunction<
  Types.ServiceProviderDeleteMutation,
  Types.ServiceProviderDeleteMutationVariables
>

/**
 * __useServiceProviderDeleteMutation__
 *
 * To run a mutation, you first call `useServiceProviderDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceProviderDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceProviderDeleteMutation, { data, loading, error }] = useServiceProviderDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceProviderDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceProviderDeleteMutation,
    Types.ServiceProviderDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceProviderDeleteMutation,
    Types.ServiceProviderDeleteMutationVariables
  >(ServiceProviderDeleteDocument, options)
}
export type ServiceProviderDeleteMutationHookResult = ReturnType<
  typeof useServiceProviderDeleteMutation
>
export type ServiceProviderDeleteMutationResult =
  Apollo.MutationResult<Types.ServiceProviderDeleteMutation>
export type ServiceProviderDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceProviderDeleteMutation,
  Types.ServiceProviderDeleteMutationVariables
>
export const ServiceProviderUpdateDocument = gql`
  mutation ServiceProviderUpdate(
    $id: ID!
    $input: ServiceProviderUpdateInput!
  ) {
    serviceProviderUpdate(id: $id, input: $input) {
      serviceProvider {
        ...ServiceProviderDetail
      }
    }
  }
  ${ServiceProviderDetailFragmentDoc}
`
export type ServiceProviderUpdateMutationFn = Apollo.MutationFunction<
  Types.ServiceProviderUpdateMutation,
  Types.ServiceProviderUpdateMutationVariables
>

/**
 * __useServiceProviderUpdateMutation__
 *
 * To run a mutation, you first call `useServiceProviderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceProviderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceProviderUpdateMutation, { data, loading, error }] = useServiceProviderUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceProviderUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ServiceProviderUpdateMutation,
    Types.ServiceProviderUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ServiceProviderUpdateMutation,
    Types.ServiceProviderUpdateMutationVariables
  >(ServiceProviderUpdateDocument, options)
}
export type ServiceProviderUpdateMutationHookResult = ReturnType<
  typeof useServiceProviderUpdateMutation
>
export type ServiceProviderUpdateMutationResult =
  Apollo.MutationResult<Types.ServiceProviderUpdateMutation>
export type ServiceProviderUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.ServiceProviderUpdateMutation,
  Types.ServiceProviderUpdateMutationVariables
>
export const ServiceProviderDetailDocument = gql`
  query ServiceProviderDetail(
    $id: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: ServiceProviderContactSortInput
  ) {
    serviceProvider(id: $id) {
      ...ServiceProviderDetail
      contacts(
        before: $before
        after: $after
        first: $first
        last: $last
        sort: $sort
      ) {
        edges {
          node {
            ...ServiceProviderDetailContact
          }
        }
        pageInfo {
          ...PageInfo
        }
        totalCount
      }
      availableServiceTypes {
        ...ServiceProviderAvailableServiceType
      }
    }
  }
  ${ServiceProviderDetailFragmentDoc}
  ${ServiceProviderDetailContactFragmentDoc}
  ${PageInfoFragmentDoc}
  ${ServiceProviderAvailableServiceTypeFragmentDoc}
`

/**
 * __useServiceProviderDetailQuery__
 *
 * To run a query within a React component, call `useServiceProviderDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceProviderDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceProviderDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useServiceProviderDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.ServiceProviderDetailQuery,
    Types.ServiceProviderDetailQueryVariables
  > &
    (
      | { variables: Types.ServiceProviderDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.ServiceProviderDetailQuery,
    Types.ServiceProviderDetailQueryVariables
  >(ServiceProviderDetailDocument, options)
}
export function useServiceProviderDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ServiceProviderDetailQuery,
    Types.ServiceProviderDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.ServiceProviderDetailQuery,
    Types.ServiceProviderDetailQueryVariables
  >(ServiceProviderDetailDocument, options)
}
export function useServiceProviderDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.ServiceProviderDetailQuery,
        Types.ServiceProviderDetailQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.ServiceProviderDetailQuery,
    Types.ServiceProviderDetailQueryVariables
  >(ServiceProviderDetailDocument, options)
}
export type ServiceProviderDetailQueryHookResult = ReturnType<
  typeof useServiceProviderDetailQuery
>
export type ServiceProviderDetailLazyQueryHookResult = ReturnType<
  typeof useServiceProviderDetailLazyQuery
>
export type ServiceProviderDetailSuspenseQueryHookResult = ReturnType<
  typeof useServiceProviderDetailSuspenseQuery
>
export type ServiceProviderDetailQueryResult = Apollo.QueryResult<
  Types.ServiceProviderDetailQuery,
  Types.ServiceProviderDetailQueryVariables
>
export const ServiceProviderListDocument = gql`
  query ServiceProviderList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: ServiceProvidersFilterInput
    $sort: ServiceProvidersSortInput
  ) {
    serviceProviders(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
      sort: $sort
    ) {
      edges {
        cursor
        node {
          ...ServiceProviderList
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${ServiceProviderListFragmentDoc}
`

/**
 * __useServiceProviderListQuery__
 *
 * To run a query within a React component, call `useServiceProviderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceProviderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceProviderListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useServiceProviderListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ServiceProviderListQuery,
    Types.ServiceProviderListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.ServiceProviderListQuery,
    Types.ServiceProviderListQueryVariables
  >(ServiceProviderListDocument, options)
}
export function useServiceProviderListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ServiceProviderListQuery,
    Types.ServiceProviderListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.ServiceProviderListQuery,
    Types.ServiceProviderListQueryVariables
  >(ServiceProviderListDocument, options)
}
export function useServiceProviderListSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.ServiceProviderListQuery,
        Types.ServiceProviderListQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.ServiceProviderListQuery,
    Types.ServiceProviderListQueryVariables
  >(ServiceProviderListDocument, options)
}
export type ServiceProviderListQueryHookResult = ReturnType<
  typeof useServiceProviderListQuery
>
export type ServiceProviderListLazyQueryHookResult = ReturnType<
  typeof useServiceProviderListLazyQuery
>
export type ServiceProviderListSuspenseQueryHookResult = ReturnType<
  typeof useServiceProviderListSuspenseQuery
>
export type ServiceProviderListQueryResult = Apollo.QueryResult<
  Types.ServiceProviderListQuery,
  Types.ServiceProviderListQueryVariables
>
export const UserTeamAddDocument = gql`
  mutation UserTeamAdd($input: UserTeamCreateInput!) {
    userTeamCreate(input: $input) {
      userTeam {
        ...UserTeamDetail
      }
    }
  }
  ${UserTeamDetailFragmentDoc}
`
export type UserTeamAddMutationFn = Apollo.MutationFunction<
  Types.UserTeamAddMutation,
  Types.UserTeamAddMutationVariables
>

/**
 * __useUserTeamAddMutation__
 *
 * To run a mutation, you first call `useUserTeamAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserTeamAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userTeamAddMutation, { data, loading, error }] = useUserTeamAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserTeamAddMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UserTeamAddMutation,
    Types.UserTeamAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.UserTeamAddMutation,
    Types.UserTeamAddMutationVariables
  >(UserTeamAddDocument, options)
}
export type UserTeamAddMutationHookResult = ReturnType<
  typeof useUserTeamAddMutation
>
export type UserTeamAddMutationResult =
  Apollo.MutationResult<Types.UserTeamAddMutation>
export type UserTeamAddMutationOptions = Apollo.BaseMutationOptions<
  Types.UserTeamAddMutation,
  Types.UserTeamAddMutationVariables
>
export const UserTeamDeleteDocument = gql`
  mutation UserTeamDelete($id: ID!) {
    userTeamDelete(id: $id) {
      __typename
    }
  }
`
export type UserTeamDeleteMutationFn = Apollo.MutationFunction<
  Types.UserTeamDeleteMutation,
  Types.UserTeamDeleteMutationVariables
>

/**
 * __useUserTeamDeleteMutation__
 *
 * To run a mutation, you first call `useUserTeamDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserTeamDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userTeamDeleteMutation, { data, loading, error }] = useUserTeamDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTeamDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UserTeamDeleteMutation,
    Types.UserTeamDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.UserTeamDeleteMutation,
    Types.UserTeamDeleteMutationVariables
  >(UserTeamDeleteDocument, options)
}
export type UserTeamDeleteMutationHookResult = ReturnType<
  typeof useUserTeamDeleteMutation
>
export type UserTeamDeleteMutationResult =
  Apollo.MutationResult<Types.UserTeamDeleteMutation>
export type UserTeamDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.UserTeamDeleteMutation,
  Types.UserTeamDeleteMutationVariables
>
export const UserTeamUpdateDocument = gql`
  mutation UserTeamUpdate($id: ID!, $input: UserTeamUpdateInput!) {
    userTeamUpdate(id: $id, input: $input) {
      userTeam {
        ...UserTeamDetail
      }
    }
  }
  ${UserTeamDetailFragmentDoc}
`
export type UserTeamUpdateMutationFn = Apollo.MutationFunction<
  Types.UserTeamUpdateMutation,
  Types.UserTeamUpdateMutationVariables
>

/**
 * __useUserTeamUpdateMutation__
 *
 * To run a mutation, you first call `useUserTeamUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserTeamUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userTeamUpdateMutation, { data, loading, error }] = useUserTeamUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserTeamUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UserTeamUpdateMutation,
    Types.UserTeamUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.UserTeamUpdateMutation,
    Types.UserTeamUpdateMutationVariables
  >(UserTeamUpdateDocument, options)
}
export type UserTeamUpdateMutationHookResult = ReturnType<
  typeof useUserTeamUpdateMutation
>
export type UserTeamUpdateMutationResult =
  Apollo.MutationResult<Types.UserTeamUpdateMutation>
export type UserTeamUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.UserTeamUpdateMutation,
  Types.UserTeamUpdateMutationVariables
>
export const UserTeamAddOptionsDocument = gql`
  query UserTeamAddOptions {
    businessEntities(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
    serviceTypes(first: 100) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`

/**
 * __useUserTeamAddOptionsQuery__
 *
 * To run a query within a React component, call `useUserTeamAddOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTeamAddOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTeamAddOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTeamAddOptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UserTeamAddOptionsQuery,
    Types.UserTeamAddOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.UserTeamAddOptionsQuery,
    Types.UserTeamAddOptionsQueryVariables
  >(UserTeamAddOptionsDocument, options)
}
export function useUserTeamAddOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UserTeamAddOptionsQuery,
    Types.UserTeamAddOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.UserTeamAddOptionsQuery,
    Types.UserTeamAddOptionsQueryVariables
  >(UserTeamAddOptionsDocument, options)
}
export function useUserTeamAddOptionsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.UserTeamAddOptionsQuery,
        Types.UserTeamAddOptionsQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.UserTeamAddOptionsQuery,
    Types.UserTeamAddOptionsQueryVariables
  >(UserTeamAddOptionsDocument, options)
}
export type UserTeamAddOptionsQueryHookResult = ReturnType<
  typeof useUserTeamAddOptionsQuery
>
export type UserTeamAddOptionsLazyQueryHookResult = ReturnType<
  typeof useUserTeamAddOptionsLazyQuery
>
export type UserTeamAddOptionsSuspenseQueryHookResult = ReturnType<
  typeof useUserTeamAddOptionsSuspenseQuery
>
export type UserTeamAddOptionsQueryResult = Apollo.QueryResult<
  Types.UserTeamAddOptionsQuery,
  Types.UserTeamAddOptionsQueryVariables
>
export const UserTeamDetailDocument = gql`
  query UserTeamDetail($id: ID!) {
    userTeam(id: $id) {
      ...UserTeamDetail
    }
    businessEntities(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
    serviceTypes(first: 100) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
  ${UserTeamDetailFragmentDoc}
`

/**
 * __useUserTeamDetailQuery__
 *
 * To run a query within a React component, call `useUserTeamDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTeamDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTeamDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTeamDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.UserTeamDetailQuery,
    Types.UserTeamDetailQueryVariables
  > &
    (
      | { variables: Types.UserTeamDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.UserTeamDetailQuery,
    Types.UserTeamDetailQueryVariables
  >(UserTeamDetailDocument, options)
}
export function useUserTeamDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UserTeamDetailQuery,
    Types.UserTeamDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.UserTeamDetailQuery,
    Types.UserTeamDetailQueryVariables
  >(UserTeamDetailDocument, options)
}
export function useUserTeamDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.UserTeamDetailQuery,
        Types.UserTeamDetailQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.UserTeamDetailQuery,
    Types.UserTeamDetailQueryVariables
  >(UserTeamDetailDocument, options)
}
export type UserTeamDetailQueryHookResult = ReturnType<
  typeof useUserTeamDetailQuery
>
export type UserTeamDetailLazyQueryHookResult = ReturnType<
  typeof useUserTeamDetailLazyQuery
>
export type UserTeamDetailSuspenseQueryHookResult = ReturnType<
  typeof useUserTeamDetailSuspenseQuery
>
export type UserTeamDetailQueryResult = Apollo.QueryResult<
  Types.UserTeamDetailQuery,
  Types.UserTeamDetailQueryVariables
>
export const UserTeamListDocument = gql`
  query UserTeamList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: UserTeamsFilterInput
    $sort: UserTeamsSortInput
  ) {
    userTeams(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
      sort: $sort
    ) {
      edges {
        cursor
        node {
          ...UserTeamList
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${UserTeamListFragmentDoc}
`

/**
 * __useUserTeamListQuery__
 *
 * To run a query within a React component, call `useUserTeamListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTeamListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTeamListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUserTeamListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UserTeamListQuery,
    Types.UserTeamListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.UserTeamListQuery,
    Types.UserTeamListQueryVariables
  >(UserTeamListDocument, options)
}
export function useUserTeamListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UserTeamListQuery,
    Types.UserTeamListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.UserTeamListQuery,
    Types.UserTeamListQueryVariables
  >(UserTeamListDocument, options)
}
export function useUserTeamListSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.UserTeamListQuery,
        Types.UserTeamListQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.UserTeamListQuery,
    Types.UserTeamListQueryVariables
  >(UserTeamListDocument, options)
}
export type UserTeamListQueryHookResult = ReturnType<
  typeof useUserTeamListQuery
>
export type UserTeamListLazyQueryHookResult = ReturnType<
  typeof useUserTeamListLazyQuery
>
export type UserTeamListSuspenseQueryHookResult = ReturnType<
  typeof useUserTeamListSuspenseQuery
>
export type UserTeamListQueryResult = Apollo.QueryResult<
  Types.UserTeamListQuery,
  Types.UserTeamListQueryVariables
>
export const ChangeUserPasswordDocument = gql`
  mutation ChangeUserPassword($newPassword: String!, $oldPassword: String!) {
    passwordChange(newPassword: $newPassword, oldPassword: $oldPassword) {
      accessToken
      refreshToken
    }
  }
`
export type ChangeUserPasswordMutationFn = Apollo.MutationFunction<
  Types.ChangeUserPasswordMutation,
  Types.ChangeUserPasswordMutationVariables
>

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ChangeUserPasswordMutation,
    Types.ChangeUserPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.ChangeUserPasswordMutation,
    Types.ChangeUserPasswordMutationVariables
  >(ChangeUserPasswordDocument, options)
}
export type ChangeUserPasswordMutationHookResult = ReturnType<
  typeof useChangeUserPasswordMutation
>
export type ChangeUserPasswordMutationResult =
  Apollo.MutationResult<Types.ChangeUserPasswordMutation>
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.ChangeUserPasswordMutation,
  Types.ChangeUserPasswordMutationVariables
>
export const UserAddDocument = gql`
  mutation UserAdd($input: UserCreateInput!, $redirectUrl: String) {
    userCreate(input: $input, redirectUrl: $redirectUrl) {
      user {
        ...UserDetail
      }
    }
  }
  ${UserDetailFragmentDoc}
`
export type UserAddMutationFn = Apollo.MutationFunction<
  Types.UserAddMutation,
  Types.UserAddMutationVariables
>

/**
 * __useUserAddMutation__
 *
 * To run a mutation, you first call `useUserAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAddMutation, { data, loading, error }] = useUserAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useUserAddMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UserAddMutation,
    Types.UserAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.UserAddMutation,
    Types.UserAddMutationVariables
  >(UserAddDocument, options)
}
export type UserAddMutationHookResult = ReturnType<typeof useUserAddMutation>
export type UserAddMutationResult = Apollo.MutationResult<Types.UserAddMutation>
export type UserAddMutationOptions = Apollo.BaseMutationOptions<
  Types.UserAddMutation,
  Types.UserAddMutationVariables
>
export const UserDeleteDocument = gql`
  mutation UserDelete($id: ID!) {
    userDelete(id: $id) {
      __typename
    }
  }
`
export type UserDeleteMutationFn = Apollo.MutationFunction<
  Types.UserDeleteMutation,
  Types.UserDeleteMutationVariables
>

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UserDeleteMutation,
    Types.UserDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.UserDeleteMutation,
    Types.UserDeleteMutationVariables
  >(UserDeleteDocument, options)
}
export type UserDeleteMutationHookResult = ReturnType<
  typeof useUserDeleteMutation
>
export type UserDeleteMutationResult =
  Apollo.MutationResult<Types.UserDeleteMutation>
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.UserDeleteMutation,
  Types.UserDeleteMutationVariables
>
export const UserSignatureUpdateDocument = gql`
  mutation UserSignatureUpdate($id: ID!, $signatureFile: Upload!) {
    userSignatureUpdate(id: $id, input: { file: $signatureFile }) {
      user {
        ...UserDetail
      }
    }
  }
  ${UserDetailFragmentDoc}
`
export type UserSignatureUpdateMutationFn = Apollo.MutationFunction<
  Types.UserSignatureUpdateMutation,
  Types.UserSignatureUpdateMutationVariables
>

/**
 * __useUserSignatureUpdateMutation__
 *
 * To run a mutation, you first call `useUserSignatureUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSignatureUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSignatureUpdateMutation, { data, loading, error }] = useUserSignatureUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      signatureFile: // value for 'signatureFile'
 *   },
 * });
 */
export function useUserSignatureUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UserSignatureUpdateMutation,
    Types.UserSignatureUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.UserSignatureUpdateMutation,
    Types.UserSignatureUpdateMutationVariables
  >(UserSignatureUpdateDocument, options)
}
export type UserSignatureUpdateMutationHookResult = ReturnType<
  typeof useUserSignatureUpdateMutation
>
export type UserSignatureUpdateMutationResult =
  Apollo.MutationResult<Types.UserSignatureUpdateMutation>
export type UserSignatureUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.UserSignatureUpdateMutation,
  Types.UserSignatureUpdateMutationVariables
>
export const UserUpdateDocument = gql`
  mutation UserUpdate($id: ID!, $input: UserUpdateInput!) {
    userUpdate(id: $id, input: $input) {
      user {
        ...UserDetail
      }
    }
  }
  ${UserDetailFragmentDoc}
`
export type UserUpdateMutationFn = Apollo.MutationFunction<
  Types.UserUpdateMutation,
  Types.UserUpdateMutationVariables
>

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UserUpdateMutation,
    Types.UserUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    Types.UserUpdateMutation,
    Types.UserUpdateMutationVariables
  >(UserUpdateDocument, options)
}
export type UserUpdateMutationHookResult = ReturnType<
  typeof useUserUpdateMutation
>
export type UserUpdateMutationResult =
  Apollo.MutationResult<Types.UserUpdateMutation>
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<
  Types.UserUpdateMutation,
  Types.UserUpdateMutationVariables
>
export const UserAddOptionsDocument = gql`
  query UserAddOptions {
    userTeams(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

/**
 * __useUserAddOptionsQuery__
 *
 * To run a query within a React component, call `useUserAddOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAddOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAddOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAddOptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UserAddOptionsQuery,
    Types.UserAddOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.UserAddOptionsQuery,
    Types.UserAddOptionsQueryVariables
  >(UserAddOptionsDocument, options)
}
export function useUserAddOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UserAddOptionsQuery,
    Types.UserAddOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.UserAddOptionsQuery,
    Types.UserAddOptionsQueryVariables
  >(UserAddOptionsDocument, options)
}
export function useUserAddOptionsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.UserAddOptionsQuery,
        Types.UserAddOptionsQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.UserAddOptionsQuery,
    Types.UserAddOptionsQueryVariables
  >(UserAddOptionsDocument, options)
}
export type UserAddOptionsQueryHookResult = ReturnType<
  typeof useUserAddOptionsQuery
>
export type UserAddOptionsLazyQueryHookResult = ReturnType<
  typeof useUserAddOptionsLazyQuery
>
export type UserAddOptionsSuspenseQueryHookResult = ReturnType<
  typeof useUserAddOptionsSuspenseQuery
>
export type UserAddOptionsQueryResult = Apollo.QueryResult<
  Types.UserAddOptionsQuery,
  Types.UserAddOptionsQueryVariables
>
export const UserDetailDocument = gql`
  query UserDetail($id: ID!) {
    user(id: $id) {
      ...UserDetail
    }
    permissions {
      code
      name
    }
    userTeams(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
`

/**
 * __useUserDetailQuery__
 *
 * To run a query within a React component, call `useUserDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Types.UserDetailQuery,
    Types.UserDetailQueryVariables
  > &
    (
      | { variables: Types.UserDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.UserDetailQuery,
    Types.UserDetailQueryVariables
  >(UserDetailDocument, options)
}
export function useUserDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UserDetailQuery,
    Types.UserDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.UserDetailQuery,
    Types.UserDetailQueryVariables
  >(UserDetailDocument, options)
}
export function useUserDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.UserDetailQuery,
        Types.UserDetailQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.UserDetailQuery,
    Types.UserDetailQueryVariables
  >(UserDetailDocument, options)
}
export type UserDetailQueryHookResult = ReturnType<typeof useUserDetailQuery>
export type UserDetailLazyQueryHookResult = ReturnType<
  typeof useUserDetailLazyQuery
>
export type UserDetailSuspenseQueryHookResult = ReturnType<
  typeof useUserDetailSuspenseQuery
>
export type UserDetailQueryResult = Apollo.QueryResult<
  Types.UserDetailQuery,
  Types.UserDetailQueryVariables
>
export const UserListDocument = gql`
  query UserList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: UsersFilterInput
    $sort: UsersSortInput
  ) {
    users(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
      sort: $sort
    ) {
      edges {
        cursor
        node {
          ...UserList
          avatar(size: 48) {
            url
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${UserListFragmentDoc}
`

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUserListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UserListQuery,
    Types.UserListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Types.UserListQuery,
    Types.UserListQueryVariables
  >(UserListDocument, options)
}
export function useUserListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UserListQuery,
    Types.UserListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Types.UserListQuery,
    Types.UserListQueryVariables
  >(UserListDocument, options)
}
export function useUserListSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        Types.UserListQuery,
        Types.UserListQueryVariables
      >,
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSuspenseQuery<
    Types.UserListQuery,
    Types.UserListQueryVariables
  >(UserListDocument, options)
}
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>
export type UserListLazyQueryHookResult = ReturnType<
  typeof useUserListLazyQuery
>
export type UserListSuspenseQueryHookResult = ReturnType<
  typeof useUserListSuspenseQuery
>
export type UserListQueryResult = Apollo.QueryResult<
  Types.UserListQuery,
  Types.UserListQueryVariables
>
